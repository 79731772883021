<template>
    <div>
      <vg-modal
          v-show="!isModalConfirmDeleteOpened"
          :width="'800px'"
          class="vg-intervention-modal"
          @close="close"
      >
          <template #header>
              <span v-if="isSansContrat">
                  {{$t('modal-title-sans-contrat')}}
              </span>
              <span v-else-if="isAnUpdateForm">{{$t('modal-title-update')}}
                  <b><vg-intervention-numero-viewer :intervention="intervention"></vg-intervention-numero-viewer></b>
              </span>
              <span v-else-if="!defaultStatut">{{$t('modal-title-create-intervention')}}</span>
              <span v-else-if="defaultStatut=='intervention-realisee'">{{$t('modal-title-create-intervention')}}</span>
              <span v-else-if="defaultStatut=='intervention-prevue'">{{$t('modal-title-create-date-previsionnelle')}}</span>
          </template>
        <template #body>
          <form id="intervention-form"
              ref="intervention-form"
              onSubmit="return false;"
              v-show="!isLoadingIntervention">
              <!-- formulaire create & update intervention -->
              <vg-input v-if="(!idTiers || intervention.tiers_id) && !idContrat"
                  :title="$t('tiers')"
                  isRequiredValue>
                  <!-- select tiers -->
                  <vg-tiers-selector v-model="intervention.tiers_id"
                      @input="setIntitule()"
                      :isClearable="false" />
              </vg-input>
              <vg-input v-if="(!idContrat || intervention.contrat_id) && intervention.tiers_id && !intervention.fichesav_id && !isSansContrat"
                  :title="$t('contrat')"
                  isRequiredValue>
                  <!-- select contrat -->
                  <vg-contrat-selector v-model="intervention.contrat_id"
                      v-if="hasNoContrat!==true"
                      :idTiers="intervention.tiers_id"
                      @input="setIntitule()"
                      :isClearable="canBeCreatedWithoutContrat"/>
                  <vg-checkbox :label="$t('aucun-contrat')" v-if="!idContrat && (canBeCreatedWithoutContrat || (isAnUpdateForm && hasNoContrat))"
                      style="margin-top: 10px;"
                      :defaultChecked="hasNoContrat"
                      @checked="hasNoContrat=true;intervention.contrat_id=null;" 
                      @unchecked="hasNoContrat=false;"/>
              </vg-input>
              <vg-input v-if="(defaultStatut=='intervention-prevue' || intervention.status=='intervention-prevue') || isSansContrat"
                  v-show="showSiteSelector && hasManySites"
                  :title="$t('restriction-site')">
                  <vg-lieux-selector v-model="intervention.site_id"
                      :filters="sitesFilters"
                      @input="onChangeInputPrestataire"
                      @after-feed="onAfterFeedSitesSelector"/>
              </vg-input>
              <vg-input v-else-if="idMaintenance && maintenance"
                  v-show="false"
                  :title="$t('restriction-site')">
                  <vg-lieux-selector v-model="intervention.site_id"
                      :filters="ficheCurativeSiteFilters"
                      @input="onChangeInputPrestataire"
                      @after-feed="onAfterFeedSitesSelector"/>
              </vg-input>
              <vg-input v-if="((defaultStatut=='intervention-prevue' || intervention.status=='intervention-prevue') && !intervention.contrat_id && !isAnUpdateForm && hasNoContrat===true) || isSansContrat"
                  v-show="showSiteSelector"
                  :title="$t('equipements-concernes')">
                  <div style="width: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;gap:5px;margin-bottom: 10px;" 
                      v-if="selectedEquipements && selectedEquipements.length!=0">
                      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;gap:10px;"
                          v-for="(equipement, index) in selectedEquipements">
                          <vg-equipement-viewer :equipement="equipement" 
                              :key="'equipement-viewer-'+index" />
                          <vg-button type="default-danger" size="sm" @click="onRemoveSelectedEquipement(equipement, index)">{{ $t("remove") }}</vg-button>
                      </div>
                  </div>
                  <div style="margin-bottom: 10px;" v-else>
                      {{ $t('par-default-tous') }}
                  </div>
                  <vg-equipement-selector v-model="selectedEquipement"
                      key="equipement-selector"
                      :attributeValue="null"
                      :agFilters="equipementsFilters"
                      @input="onSelectEquipement"/>
              </vg-input>
              <vg-input v-if="(defaultStatut=='intervention-prevue' || intervention.status=='intervention-prevue') && isAccountIsPrestataire && !isAnUpdateForm"
                  :title="$t('affecter-des-ressources')">
                  <vg-users-selector v-model="intervention.users"
                      :attributeValue="null"
                      multiple
                      @input="onChangeInputPrestataire"/>
              </vg-input>
              <vg-input v-if="isPeriodique && !defaultStatut"
                  :title="$t('statut')"
                  :is-required-value="true">
                  <!-- select statut -->
                  <vg-intervention-statut-selector v-model="intervention.status">
                  </vg-intervention-statut-selector>
              </vg-input>
              <!-- datetime picker date prévisionnelle -->
              <vg-input v-if="(!isAnUpdateForm && (defaultStatut=='intervention-prevue' || intervention.status=='intervention-prevue')) || (isAnUpdateForm && intervention.status!='intervention-realisee')"
                  v-model="intervention.datePrevisionnelleDebut"
                  :title="$t('date-previsionnelle-debut')"
                  isRequiredValue
                  :inputType="'datetime-local'"
                  :pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'"
                  @input="onChangeDatePrevisionnelleDebut">
  
              </vg-input>
              <vg-input v-if="(!isAnUpdateForm && (defaultStatut=='intervention-prevue' || intervention.status=='intervention-prevue')) || (isAnUpdateForm && intervention.status!='intervention-realisee')"
                  v-model="intervention.datePrevisionnelleFin"
                  :title="$t('date-previsionnelle-fin')"
                  isRequiredValue
                  :inputType="'datetime-local'"
                  :pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'">
  
              </vg-input>
              <vg-input v-if="intervention.status=='intervention-realisee'"
                  v-model="intervention.dateEffectiveDebut"
                  :title="$t('date-effective-debut')"
                  isRequiredValue
                  :inputType="'datetime-local'"
                  :pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'"
                  @input="onChangeDateEffectiveDebut">
  
              </vg-input>
              <vg-input v-if="intervention.status=='intervention-realisee'"
                  v-model="intervention.dateEffectiveFin"
                  :title="$t('date-effective-fin')"
                  isRequiredValue
                  :inputType="'datetime-local'"
                  :pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'">
  
              </vg-input>
              <vg-input v-if="!isPonctuelle"
                  v-model="intervention.intitule"
                  :title="$t('intitule')">
  
              </vg-input>
              <vg-input
                  v-model="intervention.commentaire"
                  :title="$t('commentaire')"
                  :inputType="'textarea'">
  
              </vg-input>
              <vg-input v-if="isPeriodique && intervention.status=='intervention-prevue'"
                  :title="$t('rdv-confirme')">
                  <!-- checkbox -->
                  <vg-checkbox
                      :defaultChecked="parseInt(intervention.confirmed) || false"
                      @checked="intervention.confirmed=true"
                      @unchecked="intervention.confirmed=false">
                  </vg-checkbox>
              </vg-input>
              <vg-input v-if="isPonctuelle"
                  v-model="intervention.cout"
                  :title="$t('cout', {devise: $app.devise})" 
                  inputType="financial"/>
              <vg-input v-if="isPonctuelle"
                  :title="$t('note')">
                  <!-- composant note -->
                  <vg-rate v-model="intervention.rate">
                  </vg-rate>
              </vg-input>
              <!--vg-input v-if="isPeriodique"
                  v-model="intervention.restrictionsite"
                  :title="$t('restriction-site')">
  
              </vg-input-->
              <!--vg-input v-if="isPonctuelle"
                  :title="$t('maintenance-associee')"-->
                  <!-- select fm -->
                  <!--vg-maintenance-selector v-model="intervention.fichesav_id"
                      >
                  </vg-maintenance-selector>
              </vg-input-->
              <br>
              <vg-input v-if="defaultStatut!='intervention-prevue' || intervention.status!='intervention-prevue'"
                  :title="$t('documents')">
                  <!-- uploader documents -->
                  <vg-files-uploader v-if="!isTagsEmpty && ((isAnUpdateForm && !fileToUpload) || (!isAnUpdateForm))"
                      ref="intervention-form-uploader"
                      :isUploadOnFileChange="false"
                      :tagsList="documentsInterventionTags"
                      @file-change="onFileChange" />
                  <vg-files-progress-upload-bar v-else-if="isAnUpdateForm && fileToUpload && VgFilesMixins.uploadState"
                      :transferredBytes="VgFilesMixins.uploadState.bytesTransferred"
                      :totalBytes="VgFilesMixins.uploadState.totalBytes" />
                  <br>
                  <vg-files-viewer-list-section v-if="isAnUpdateForm"
                      :files="documents" />
              </vg-input>
  
              <vg-input v-if="isSansContrat" :title="$t('reserves')">
                  <div v-if="reserves && reserves.length!=0" style="display: flex;flex-direction:column;justify-content: flex-start;align-items: flex-start;gap: 10px;margin-bottom: 10px;">
                      <div v-for="reserve,index in reserves" :key="index" style="display: flex;justify-content: flex-start;align-items: flex-start;gap:10px;" >
                          <vg-equipement-viewer :equipement="reserve.materiel" :key="'reserve-eq-'+index"/>
                          <span>{{reserve.commentaireClient}}</span>
                          <vg-button
                              type="default-danger"
                              size="sm"
                              @click="removeReserve(reserve,index)">
                              <i class="fas fa-times"></i>
                          </vg-button>
                      </div>
                  </div>
                  <div>
                      <vg-equipement-selector v-model="reserve.materiel"
                          key="equipement-reserve-selector"
                          :attributeValue="null"
                          :agFilters="equipementsReservesFilters"
                          style="width:100%;margin-right:5px;margin-bottom:10px;" />
                      <vg-input v-model="reserve.commentaireClient"
                          :placeholder="$t('description')"
                          style="width:100%;margin-right:5px;" />
                      <vg-button 
                          :disabled="isAddReserveDisabled"
                          :type="'success'"
                          @click="addReserve">
                          {{$t("add-reserve")}}
                      </vg-button>
                  </div>
              </vg-input>
  
              <input
                v-show="false"
                ref="intervention-submit"
                type="submit"
              >
          </form>
          <div v-if="isLoadingIntervention" style="color: #059CFF; font-size:14px;">
              {{ $t("chargement-intervention") }}
          </div>
        </template>
          <template #footer>
              <vg-button v-if="isAnUpdateForm"
                  :type="'danger'"
                  :disabled="isLoading"
                  @click="isModalConfirmDeleteOpened=true">
                  {{$t("delete")}}
              </vg-button>
              <vg-button
                  :type="'default'"
                  :disabled="isLoading"
                  @click="close()">
                  {{$t("cancel")}}
              </vg-button>
              <vg-button
                  :type="'success'"
                  :disabled="isBoutonSaveDisabled || isLoading"
                  @click="onSave">
                  {{isSansContrat?$t("save-sans-contrat"):$t("save")}}
              </vg-button>
          </template>
      </vg-modal>
      <dialog-secured-action
          v-if="isModalConfirmDeleteOpened"
          :valid-response="'1'"
          @save="onDeleteIntervention"
          @close="isModalConfirmDeleteOpened=false;">
          <template v-slot:header-title>
              {{ $t("confirm-delete-intervention") }} <vg-intervention-numero-viewer :intervention="intervention"></vg-intervention-numero-viewer> ?
          </template>
      </dialog-secured-action>
    </div>
  </template>
  <script>
  import InterventionsMixins from "src/mixins/InterventionsMixins.js";
  import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
  import TagsMixins from "src/mixins/TagsMixins.js";
  import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";
  
  import VgButton from "src/components/Vg/VgButton.vue";
  import VgInput from "src/components/Vg/VgInput.vue";
  import VgSelect from "src/components/Vg/VgSelect.vue";
  import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
  import VgRate from "src/components/Vg/VgRate.vue";
  
  import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
  import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
  import VgFilesProgressUploadBar from "src/components/Vg/Files/VgFilesProgressUploadBar.vue";
  
  import VgUsersSelector from "src/components/Vg/Selectors/VgUsersSelector.vue";
  import VgLieuxSelector from "src/components/Vg/Selectors/VgLieuxSelector.vue";
  import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
  import VgContratSelector from "src/components/Vg/Selectors/VgContratSelector.vue";
  import VgMaintenanceSelector from "src/components/Vg/Selectors/VgMaintenanceSelector.vue";
  import VgInterventionStatutSelector from "src/components/Vg/Interventions/VgInterventionStatutSelector.vue";
  import VgInterventionNumeroViewer from "src/components/Vg/Interventions/VgInterventionNumeroViewer.vue";
  import VgEquipementSelector from "src/components/Vg/Selectors/VgEquipementSelector.vue";
  import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";
  
  import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
  
  import Metadatas from "src/services/Metadatas.js";
  import { mapGetters } from 'vuex';
  import moment from "moment";
  
  export default {
      name: 'vg-intervention-form',
      components:{
          VgButton,
          VgInput,
          VgSelect,
          VgCheckbox,
          VgRate,
          DialogSecuredAction,
          VgUsersSelector,
          VgLieuxSelector,
          VgTiersSelector,
          VgContratSelector,
          VgMaintenanceSelector,
          VgInterventionStatutSelector,
          VgFilesUploader,
          VgFilesViewerListSection,
          VgFilesProgressUploadBar,
          VgInterventionNumeroViewer,
          VgEquipementSelector,
          VgEquipementViewer
      },
      mixins: [ InterventionsMixins, MaintenanceMixins, TagsMixins, VgFilesMixins ],
      i18n:    { "locale":navigator.language,
      "messages": {
          "fr": {
              "inter": "Inter.",
              "modal-title-create-intervention": "Valider une intervention",
              "modal-title-create-date-previsionnelle": "Programmer intervention tiers préventive",
              "modal-title-update": "Modifier l'intervention",
              "modal-title-sans-contrat": "Créer intervention tiers préventive (sans contrat)",
              "tiers": "Prestataire",
              "contrat": "Contrat",
              "date-previsionnelle-debut": "Date début prévisionnelle",
              "date-previsionnelle-fin": "Date fin prévisionnelle",
              "date-effective-debut": "Date début effective",
              "date-effective-fin": "Date fin effective",
              "statut": "Statut de l'intervention",
              "intitule": "Intitulé",
              "commentaire": "Commentaire",
              "rdv-confirme": "Rdv confirmé?",
              "cout": "Coût HT en {devise}",
              "note": "Note",
              "documents": "Documents",
              "restriction-site": "Site concerné",
              "equipements-concernes": "Equipements concernés",
              "affecter-des-ressources": "Affecter des ressources",
              "maintenance-associee": "Fiche curative associée",
              "delete": "Supprimer",
              "cancel": "Annuler",
              "save": "Enregistrer",
              "confirm-delete-intervention": "Confirmer la suppression de l'intervention",
              "month-1": "janvier",
              "month-2": "février",
              "month-3": "mars",
              "month-4": "avril",
              "month-5": "mai",
              "month-6": "juin",
              "month-7": "juillet",
              "month-8": "août",
              "month-9": "septembre",
              "month-10": "octobre",
              "month-11": "novembre",
              "month-12": "décembre",
              "chargement-intervention": "Chargement de l'intervention...",
              "remove": "Retirer",
              "par-default-tous": "Par défaut tous",
              "aucun-contrat": "Intervention sans contrat",
              "reserves":"Réserves",
              "description": "Description de la réserve",
              "add-reserve": "Ajout réserve",
              "save-sans-contrat": "Valider intervention",
              "informations-complementaires": "Informations complémentaires"
          },
          "en": {
              "modal-title-create-intervention": "Validate an intervention",
              "modal-title-create-date-previsionnelle": "Plan an intervention date",
              "modal-title-update": "Update the intervention",
              "tiers": "Provider",
              "contrat": "Contract",
              "date-previsionnelle-debut": "Planned date start",
              "date-previsionnelle-fin": "Planned date end",
              "date-effective-debut": "Start date",
              "date-effective-fin": "End date",
              "statut": "Intervention's status",
              "intitule": "title",
              "commentaire": "Comment",
              "rdv-confirme": "Meeting confirmed ?",
              "cout": "Cost excl.VAT in {devise}",
              "note": "Mark",
              "documents": "Documents",
              "restriction-site": "Site concerned",
              "equipements-concernes": "Related equipments",
              "affecter-des-ressources": "Assign resources",
              "maintenance-associee": "Linked curative sheet",
              "delete": "Delete",
              "cancel": "Cancel",
              "save": "Save",
              "confirm-delete-intervention": "Confirm intervention's deletion",
              "inter": "Inter.",
              "month-1": "January",
              "month-2": "February",
              "month-3": "March",
              "month-4": "April",
              "month-5": "May",
              "month-6": "June",
              "month-7": "July",
              "month-8": "August",
              "month-9": "September",
              "month-10": "October",
              "month-11": "November",
              "month-12": "December",
              "chargement-intervention": "Loading intervention...",
              "remove": "Remove",
              "par-default-tous": "Default all",
              "aucun-contrat": "Intervention without contract",
              "reserves":"Reservations",
              "description": "Description",
              "add-reserve": "Add reservation",
              "save-sans-contrat": "Validation intervention",
              "informations-complementaires": "Additional information"
          },
          "th": {
              "modal-title-create-intervention": "ตรวจสอบการแทรกแซง",
              "modal-title-create-date-previsionnelle": "วางแผนวันที่การแทรกแซง",
              "modal-title-update": "อัปเดตการแทรกแซง",
              "tiers": "ผู้ให้บริการ",
              "contrat": "สัญญา",
              "heure-debut": "ชั่วโมงที่เริ่มต้น",
              "heure-fin": "ชั่วโมงที่สิ้นสุด",
              "date-effective-debut": "วันที่เริ่มต้น",
              "date-effective-fin": "วันที่สิ้นสุด",
              "statut": "สถานะของการแทรกแซง",
              "intitule": "หัวข้อ",
              "commentaire": "ความคิดเห็น",
              "rdv-confirme": "ยืนยันการประชุมแล้ว ?",
              "note": "ทำเครื่องหมาย",
              "documents": "เอกสาร",
              "delete": "ลบ",
              "cancel": "ยกเลิก",
              "save": "บันทึก",
              "confirm-delete-intervention": "ยืนยันการลบการแทรกแซง"
          }
      }
  },
      props: {
          id:{
              type: Number | String,
              default: null
          },
          skipVueXStorage:{
              type: Boolean,
              default: false
          },
          /**
          * statut par default de l'intervention
          */
          defaultStatut: {
              type: String,
              default: null
          },
          /**
          * intervention est periodique
          */
          isPeriodique: {
              type: Boolean,
              default: false
          },
          /**
          * intervention est ponctuelle
          */
          isPonctuelle: {
              type: Boolean,
              default: false
          },
          /**
          * tiers lié à l'intervention
          */
          idTiers: {
              type: String | Number,
              default: null
          },
          /**
          * contrat lié à l'intervention
          */
          idContrat: {
              type: String | Number,
              default: null
          },
          /**
          * maintenance lié à l'intervention
          */
          idMaintenance: {
              type: String | Number,
              default: null
          },
          maintenance:{
              type: Object,
              default: function(){
                  return null;
              }
          },
          defaultDateDebut: {
              type: String,
              default: null
          },
          defaultDateFin: {
              type: String,
              default: null
          },
          defaultDatePrevisionnelleDebut: {
              type: String,
              default: null
          },
          defaultDatePrevisionnelleFin: {
              type: String,
              default: null
          },
          defaultDateEffectiveDebut:{
              type: String,
              default: null
          },
          defaultDateEffectiveFin:{
              type: String,
              default: null
          },
          contrat:{
              type: Object,
              default: function(){
                  return null;
              }
          },
          canBeCreatedWithoutContrat:{
              type: Boolean,
              default: false
          },
          isSansContrat:{
              type: Boolean,
              default: false
          },
          defaultSelectedEquipements:{
              type: Array,
              default: function(){
                  return [];
              }
          }
      },
      data: function() {
          return {
              intervention: {
                  tiers_id: this.idTiers,
                  contrat_id: this.idContrat,
                  site_id: null,
                  intitule: null,
                  commentaire: null,
                  datePrevisionnelleDebut: this.defaultDatePrevisionnelleDebut?moment(this.defaultDatePrevisionnelleDebut).format("YYYY-MM-DD HH:mm"):moment().format("YYYY-MM-DDT08:00"),
                  datePrevisionnelleFin: this.defaultDatePrevisionnelleFin?moment(this.defaultDatePrevisionnelleFin).format("YYYY-MM-DD HH:mm"):moment().format("YYYY-MM-DDT09:00"),
                  //datePrevisionnelle: this.defaultDatePrevisionnelleDebut?moment(this.defaultDatePrevisionnelleDebut).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD"),
                  //timePrevisionnelleDebut: this.defaultDatePrevisionnelleDebut?moment(this.defaultDatePrevisionnelleDebut).format("HH:mm"):moment().format("08:00"),
                  //timePrevisionnelleFin: this.defaultDatePrevisionnelleFin?moment(this.defaultDatePrevisionnelleFin).format("HH:mm"):moment().format("09:00"),
                  dateEffectiveDebut: this.defaultDateEffectiveDebut?moment(this.defaultDateEffectiveDebut).format("YYYY-MM-DDTHH:mm"):moment().format("YYYY-MM-DDT08:00"),
                  dateEffectiveFin: this.defaultDateEffectiveFin?moment(this.defaultDateEffectiveFin).format("YYYY-MM-DDTHH:mm"):moment().format("YYYY-MM-DDT09:00"),
                  confirmed: null,
                  cout: null,
                  numeroIntervention: null,
                  rate: null,
                  status: this.defaultStatut || "intervention-prevue",
                  userId: this.$app.appID,
                  fichesav_id: this.idMaintenance || null,
                  idUser: this.$app.idUser,
                  users: null
              },
              fileToUpload: null,
              metadatasTags: new Metadatas(),
              isModalConfirmDeleteOpened: false,
              documents: [],
              sites: [],
              isTagsEmpty: true,
              isLoading: false,
              isLoadingIntervention: false,
              selectedEquipements: this.defaultSelectedEquipements?[...this.defaultSelectedEquipements]:[],
              selectedEquipement: null,
              hasNoContrat: false,
              reserves: [],
              reserve: {
                  materiel: null,
                  commentaireClient: null
              },
          };
      },
      watch: {
          selectedIntervention: {
              handler: function(intervention){
                  ////console.log("SELECTED INTERVENTION", intervention);
                  this.intervention = Object.assign(this.intervention, intervention);
                  this.getInterventionsFiles();
              },
              deep: true
          }
      },
      created: function(){
          this.VgFilesMixins_init(null, "intervention");
          this.fetchTags();
      },
      mounted: function(){
          if(this.id && this.skipVueXStorage) this.fetchIntervention();
          else{
              if(!this.skipVueXStorage) this.intervention = Object.assign(this.intervention, this.selectedIntervention);
              if(this.idMaintenance) this.intervention.status = "intervention-realisee";
              if(this.intervention.dateEffectiveDebut) this.intervention.dateEffectiveDebut = moment(this.intervention.dateEffectiveDebut).format("YYYY-MM-DDTHH:mm");
              if(this.intervention.dateEffectiveFin) this.intervention.dateEffectiveFin = moment(this.intervention.dateEffectiveFin).format("YYYY-MM-DDTHH:mm");
              if(!this.intervention.contrat_id) this.hasNoContrat = true;
              if(this.intervention.status=="intervention-realisee"){
                  if(this.defaultDateDebut) this.intervention.dateEffectiveDebut = moment(this.defaultDateDebut).format("YYYY-MM-DDTHH:mm");
                  if(this.defaultDateFin) this.intervention.dateEffectiveFin = moment(this.defaultDateFin).format("YYYY-MM-DDTHH:mm");
              }else{
                  if(this.defaultDateDebut && this.defaultDateFin){
                      this.intervention.datePrevisionnelleDebut = moment(this.defaultDateDebut).format("YYYY-MM-DD HH:mm");
                      this.intervention.datePrevisionnelleFin = moment(this.defaultDateDebut).add(1, "hours").format("YYYY-MM-DD HH:mm");
                  }
              }
              this.setIntitule();
              this.initSite();
          }
      },
      destroyed:function(){
          if(!this.skipVueXStorage && this.isAnUpdateForm) this.$store.dispatch("InterventionsStore/setSelectedItem",null);
      },
      computed:{
          ...mapGetters({
              selectedIntervention: "InterventionsStore/getSelectedItem",
              contrats: "ContratsStore/getCollection",
              tiers: "TiersStore/getCollection",
              documentsInterventionTags: "TagsStore/getDocumentsInterventionTags"
          }),
          isAnUpdateForm: function(){
              if(this.id && this.skipVueXStorage) return true;
              else if(!this.skipVueXStorage && this.selectedIntervention) return this.selectedIntervention.hasOwnProperty('id');
              else return false;
          },
          isAccountIsPrestataire: function(){
              return this.$app.account_type=="prestataire";
          },
          sitesFilters: function(){
              let filters = {
                  type_lieu: {attr: "l.type_lieu", value: "Site", action: "equals"}
              };
              if(this.contrat && this.contrat.lieux) filters.path = {attr: "l.path", value: this.contrat.lieux.map((lieu)=>lieu.contratslieux_path), action: "start_with"};
              return filters;
          },
          ficheCurativeSiteFilters: function(){
              return {
                  type_lieu: {attr: "l.type_lieu", value: "Site", action: "equals"},
                  path: {attr: "l.path", value: this.maintenance.equipement.path, action: "equals"}
              };
          },
          showSiteSelector: function(){
              if(this.isSansContrat) return true;
              else if(this.contrat && this.contrat.lieux && this.contrat.lieux.length==1) return false;
              return true;
          },
          isBoutonSaveDisabled: function(){
              if(this.isAnUpdateForm && this.hasNoContrat) return ;
              if(this.isSansContrat || this.intervention.fichesav_id) return !(this.intervention.tiers_id && this.intervention.dateEffectiveDebut && this.intervention.dateEffectiveDebut.length!=0 && this.intervention.dateEffectiveFin && this.intervention.dateEffectiveFin.length!=0);
              else if(!this.canBeCreatedWithoutContrat && this.intervention.fichesav_id) return !(this.intervention.contrat_id && this.intervention.tiers_id && this.intervention.dateEffectiveDebut && this.intervention.dateEffectiveDebut.length!=0 && this.intervention.dateEffectiveFin && this.intervention.dateEffectiveFin.length!=0);
              else if(!this.canBeCreatedWithoutContrat && !this.intervention.fichesav_id) return !(this.intervention.contrat_id && this.intervention.tiers_id && this.intervention.datePrevisionnelleDebut && this.intervention.datePrevisionnelleDebut.length!=0 && this.intervention.datePrevisionnelleFin && this.intervention.datePrevisionnelleFin.length!=0);
              else if(this.canBeCreatedWithoutContrat && !this.intervention.fichesav_id) return !((this.hasNoContrat || this.intervention.contrat_id) && this.intervention.tiers_id && this.intervention.datePrevisionnelleDebut && this.intervention.datePrevisionnelleDebut.length!=0 && this.intervention.datePrevisionnelleFin && this.intervention.datePrevisionnelleFin.length!=0);
              else if(this.isAnUpdateForm && !this.intervention.fichesav_id) return !((this.hasNoContrat || this.intervention.contrat_id) && this.intervention.tiers_id && this.intervention.datePrevisionnelleDebut && this.intervention.datePrevisionnelleDebut.length!=0 && this.intervention.datePrevisionnelleFin && this.intervention.datePrevisionnelleFin.length!=0);
              else return !this.intervention.fichesav_id || !this.intervention.tiers_id;
          },
          selectedEquipementsIds: function(){
              return this.selectedEquipements.map((equipement)=>equipement.id);
          },
          equipementsFilters: function(){
              let site = null;
              if(this.intervention.site_id && this.sites) site = this.sites.find((s)=>s.id==this.intervention.site_id);
              let selectedEquipementsIds = this.selectedEquipements.map((equipement)=>equipement.id); 
              return {
                  site_id: {attr: "l.path", value: site?site.path:null, action: "start_with"},
                  equipement_id: {attr: "e.id", value: selectedEquipementsIds, action: "not_equals"},
                  sortie: { attr: "se.dateSortie", value: 1, action: "is_null"}
              };
          },
          isAddReserveDisabled: function(){
              return !this.reserve.materiel || !this.reserve.commentaireClient || this.reserve.commentaireClient=="";
          },
          equipementsReservesFilters: function(){
              let site = null;
              if(this.intervention.site_id && this.sites) site = this.sites.find((s)=>s.id==this.intervention.site_id);
              let selectedEquipementsIds = this.selectedEquipements.map((equipement)=>equipement.id);
              return {
                  site_id: {attr: "l.path", value: site?site.path:null, action: "start_with"},
                  equipement_id: {attr: "e.id", value: selectedEquipementsIds, action: "equals"},
                  sortie: { attr: "se.dateSortie", value: 1, action: "is_null"}
              };
          },
          hasManySites: function(){
              return this.$storage.get("has-only-one-site")==false;
          },
          hasOnlyOneSite: function(){
              return this.$storage.get("has-only-one-site")==true;
          }
      },
      methods: {
          addReserve: function(){
              this.reserves.push(Object.assign({},{},this.reserve));
              this.reserve.materiel = null;
              this.reserve.commentaireClient = null;
          },
          removeReserve: function(reserve, index){
              this.reserves.splice(index, 1);
          },
          onSelectEquipement: function(equipement){
              console.log("EQUIPEMENT SELECTED", equipement);
              this.selectedEquipements.push(equipement);
              this.selectedEquipement = null;
          },
          onRemoveSelectedEquipement: function(equipement, index){
              this.selectedEquipements.splice(index, 1);
          },
          initSite: function(){
              if(this.contrat && this.contrat.lieux && this.contrat.lieux.length==1) this.intervention.site_id = this.contrat.lieux[0].lieu_id;
          },
          fetchIntervention: function(){
              this.isLoadingIntervention = true;
              this.InterventionsMixins_getIntervention(this.id, {skipVueXStorage: this.skipVueXStorage}).then((intervention)=>{
                  this.intervention = Object.assign({}, this.intervention, intervention);
                  if(!this.intervention.contrat_id) this.hasNoContrat = true;
                  this.isLoadingIntervention = false;
              });
          },
          onAfterFeedSitesSelector: function(sites){
              console.log("AFTER FEED SITES SELECTOR-----------", sites);
              this.sites = [...sites];
          },
          onChangeInputPrestataire: function(){
              this.setIntitule();
          },
          onChangeDatePrevisionnelleDebut: function(date){
              if(date>this.intervention.datePrevisionnelleFin) this.intervention.datePrevisionnelleFin = moment(this.intervention.datePrevisionnelleDebut).add(1, "hours").format("YYYY-MM-DD HH:mm");
              this.setIntitule();
          },
          getIntitule: function(){
              let tiersName = "";
              let contratName = "";
              let date = moment(this.intervention.datePrevisionnelleDebut).format("MM")+moment(this.intervention.datePrevisionnelleDebut).format("-YY");
              if(this.intervention.tiers_id){
                  this.tiers.forEach((tier)=>{
                     tier.id == this.intervention.tiers_id ? tiersName = tier.name : null;
                  });
              }
              if(this.intervention.contrat_id){
                  this.contrats.forEach((contrat)=>{
                     contrat.id == this.intervention.contrat_id ? contratName = contrat.name : null;
                  });
              }
              return `${this.$t("inter")} ${date}  ${tiersName}  ${contratName}`;
          },
          setIntitule: function(){
              if(this.$app.account_type=="prestataire" && !this.isPonctuelle && this.sites && this.intervention.site_id){
                  let site = this.sites.find((s)=>s.id==this.intervention.site_id);
                  let intitule = site.libel_lieu;
                  if(this.intervention.users){
                      let usersNomPrenom = this.intervention.users.map((user)=>user.nom+" "+user.prenom);
                      intitule += " "+usersNomPrenom.join(" ");
                  }
                  this.intervention.intitule = intitule;
                  this.$forceUpdate();
              }else if(!this.isPonctuelle){
                  this.intervention.intitule = this.getIntitule();
                  this.$forceUpdate();
              }
          },
          getInterventionsFiles: function(){
              this.documents = this.VgFilesMixins_normalizeFilesToListSection(this.intervention.documents, this.documentsInterventionTags);
          },
          /**
          * Fetch tous les tags.
          */
          fetchTags: function(){
              this.TagsMixins_get(this.metadatasTags).then((datas)=>{
                  this.isTagsEmpty = false;
                  if(this.intervention.documents) this.getInterventionsFiles();
              });
          },
          /**
          * Change date effective fin +1h lorsque l'user change la date effective début.
          * @param string dt
          */
          onChangeDateEffectiveDebut: function(dt){
              this.intervention.dateEffectiveFin = moment(dt).add(1, "hours").format("YYYY-MM-DDTHH:mm");
          },
          /**
          * Stock le document à uploader en attente de l'enregistrement de l'intervention.
          * @param object file
          */
          onFileChange: function(file){
              this.fileToUpload = file;
              if(this.isAnUpdateForm) {
                  this.uploadFileTo(this.intervention.uid);
              }
          },
          /**
          * Upload le document sur l'intervention.
          * @param string uid - uid de l'intervention
          */
          uploadFileTo: function(uid){
              if(this.fileToUpload){
                  let currentUid = this.VgFilesMixins_getUid();
                  this.VgFilesMixins_setUid(uid);
                  this.VgFilesMixins_uploadFile(this.fileToUpload.file, this.fileToUpload.idTag).then((datas)=>{
                      this.VgFilesMixins_setUid(currentUid);
                      this.fileToUpload = null;
                      //console.log("UPLOAD FILE", datas);
                      this.$store.dispatch("InterventionsStore/addDocument", {intervention: this.intervention, file: datas});
                  });
              }
          },
          /**
          * Prepare l'intervention pour le post.
          * @// TODO: à factoriser
          */
          prepareIntervention: function(){
              if(this.intervention.status=="intervention-prevue"){
                  this.intervention.datePrevisionnelleDebut = moment(this.intervention.datePrevisionnelleDebut).format("YYYY-MM-DD HH:mm");
                  this.intervention.datePrevisionnelleFin = moment(this.intervention.datePrevisionnelleFin).format("YYYY-MM-DD HH:mm");
                  this.intervention.dateEffectiveDebut = null;
                  this.intervention.dateEffectiveFin = null;
              }else if(this.intervention.status=="intervention-realisee"){
                  this.intervention.datePrevisionnelleDebut = null;
                  this.intervention.datePrevisionnelleFin = null;
              }
              if(this.isAnUpdateForm){ // update intervention
                  delete this.intervention.contrat;
                  delete this.intervention.tiers;
                  delete this.intervention.maintenance;
                  delete this.intervention.documents;
                  delete this.intervention.validateur;
              }else{ // create intervention
                  this.intervention.equipements = [...this.selectedEquipements];
                  if(this.hasOnlyOneSite) this.intervention.site_id = this.sites[0].id;
              }
          },
          /**
          * Save l'intervention.
          */
          onSave: function(){
              this.isLoading = true;
              let form = this.$refs['intervention-form'];
              if(form.checkValidity() && this.intervention.tiers_id){
                  if(!this.isAnUpdateForm){ // création d'une intervention
                      this.prepareIntervention();
                      this.InterventionsMixins_create([this.intervention]).then((datas)=>{
                          this.uploadFileTo(datas[0].uid);
                          if(this.reserves.length!=0){
                              let reserves = this.reserves.map((reserve)=>{
                                  return {
                                      materiel_id: reserve.materiel.id,
                                      commentaireClient: reserve.commentaireClient,
                                      userId: this.$app.appID,
                                      idUser: this.$app.idUser,
                                      intervention_id: datas[0].id,
                                      isReserve: true,
                                      dateOuvertureSAV: moment().format("YYYY-MM-DD HH:mm:ss"),
                                      origin: "intervention"
                                  }
                              });
                              this.MaintenanceMixins_createMaintenances(reserves, {_stored: false}).then((datas)=>{
                                  this.$emit("save", datas[0]);
                              });
                          }else{
                              this.$emit("save", datas[0]);
                          }
                      });
                  }else{ // update d'une intervention
                      this.prepareIntervention();
                      this.InterventionsMixins_update(this.intervention, {skipVueXStorage: this.skipVueXStorage}).then((data)=>{
                          //console.log("UPDATE INTERVENTION", data);
                          this.$emit("save");
                      });
                  }
              }
          },
          close: function(){
              this.$emit('close');
          },
          onDeleteIntervention: function(){
              this.InterventionsMixins_delete(this.intervention).then((data)=>{
                  this.close();
              });
          }
      }
  };
  </script>
  <style lang="scss">
  .vg-intervention-modal{
      .modal-body{
          min-height: 300px;
      }
  }
  .datetime-input-inline{
      display:flex;
      justify-content:flex-start;
  }
  @media only screen and (max-width: 768px) {
      .datetime-input-inline{
          display: flex;
          flex-wrap: wrap;
      }
  }
  </style>
  <style lang="scss" scoped>
  
  </style>
  <docs>
  
      ### vg-intervention-form
      Create|Update|Delete intervention
  
      #### basic usage
  
      ```html static
          <vg-intervention-form v-if="intervention || isInterventionFormDisplayed"
              v-model="intervention"
              @close="isInterventionFormDisplayed=false;intervention=null;"
              @created="isInterventionFormDisplayed=false;fetch()"
              @updated="isInterventionFormDisplayed=false;fetch()"
              @deleted="isInterventionFormDisplayed=false;fetch()"
          >
          </vg-intervention-form>
      ```
  </docs>