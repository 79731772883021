
<template lang="html">
	<div class="viewer-photo-container">
		<div class="header">

			<span v-if="files && files.length!=0">
				<img v-if="files[currentIndex] && files[currentIndex].isInherited && files[currentIndex].isInherited=='1'"
				src="/static/assets/icone/categorie-equipement-gris.png"
				alt=""
				width="20px" height="20px">
				{{currentIndex+1}} / {{files.length}}
			</span>
		</div>
		<div class="body" height="60px" width="60px">
			<vg-icon url="static/assets/icone/custom/chevron-left.svg" size="30"
				key="left-icon" :color="files.length==0 || currentIndex==0?'#ccc':'#333'"
				@click="decrementCurrentIndex();isFileViewerOpenByDefault=false;"/>
			<vg-files-thumbnail v-if="files && files.length!=0"
				:key="'files-thumbnail-'+currentIndex"
                :heightImg="getThumbnailSize"
				:file="files[currentIndex]"
				:allowPreviousNavigation="files.length!=0 && currentIndex!=0"
				:allowNextNavigation="files.length!=0 && currentIndex!=files.length-1"
				:isOpenByDefault="isFileViewerOpenByDefault"
				@navigate-to-previous="onNavigateToPrevious"
				@navigate-to-next="onNavigateToNext" />
			<div v-else class="no-files">
				<vg-icon url="static/assets/icone/custom/image-off.svg" size="30" color="#ccc"/>
				<span>{{$t("no-files")}}</span>
			</div>
			<vg-icon url="static/assets/icone/custom/chevron-right.svg" size="30"
				key="left-icon" :color="files.length==0 || currentIndex==files.length-1?'#ccc':'#333'"
				@click="incrementCurrentIndex();isFileViewerOpenByDefault=false;"/>
		</div>
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgIcon from "src/components/Vg/VgIcon.vue";
	import VgFilesThumbnail from "src/components/Vg/Files/VgFilesThumbnail.vue";
    export default {
        name: 'vg-files-viewer-photo',
	    components: {
			VgButton,
			VgIcon,
			VgFilesThumbnail
        },
		filters: {

		},
		mixins:[

        ],
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "no-files": "Aucune photo"
        },
        "en": {
            "no-files": "No photo"
        },
        "th": {
            "no-files": "ไม่มีรูปภาพ"
        }
    }
},
        props: {
			files: {
				type: Array,
				default: []
			}
        },
        data: function() {
            return {
				currentIndex: 0,
				isFileViewerOpenByDefault: false
			};
        },
		watch: {
			files: function(values){

			}
		},
		filters:{

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			onNavigateToPrevious: function(){
				this.isFileViewerOpenByDefault = true;
				this.decrementCurrentIndex();
			},
			onNavigateToNext: function(){
				this.isFileViewerOpenByDefault = true;
				this.incrementCurrentIndex();
			},
			decrementCurrentIndex: function(){
				if(this.currentIndex>0){
					this.currentIndex--;
				}
			},
			incrementCurrentIndex: function(){
				if(this.currentIndex<this.files.length-1){
					this.currentIndex++;
				}
			}
		},
		computed: {
            getThumbnailSize:function(){
                return this.$vgutils.isMobile()?"124px":"250px";
            }
		}
	};
</script>

<style lang="scss" scoped>
.viewer-photo-container{
	width: 100%;
	padding: 10px;
	.header{
		>span{
			font-weight: bold;
			font-size: 11px;
			padding: 10px;
		}
		display: flex;
		justify-content: flex-end;
	}
	.body{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		>i{
			color: black;
			cursor: pointer;
		}
		>i:hover{
			border-bottom: 1px solid #f5f7f7;
		}
		>i.inactive{
			color: #e0e0e1;
		}
		>div.no-files{
			color: #e0e0e1;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
</style>
