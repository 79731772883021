<template>
    <div class="overlay" @click="handleOverlayClick">
      <div class="overlay-content" @click.stop>
        <div class="actions-btn">
          <button v-if="allowPreviousNavigation" class="action-btn" @click="$emit('previous')">
            <vg-icon url="static/assets/icone/custom/chevron-left.svg" key="chevron-left-icon" color="white"/>
          </button>
          <button v-if="allowNextNavigation" class="action-btn" @click="$emit('next')">
            <vg-icon url="static/assets/icone/custom/chevron-right.svg" key="chevron-right-icon" color="white"/>
          </button>
          <button class="action-btn" @click="openInNewTab">
            <vg-icon url="static/assets/icone/custom/external-link.svg" key="open-in-new-tab-icon" color="white"/>
          </button>
          <button class="action-btn" @click="closeViewer">
            <vg-icon url="static/assets/icone/custom/x.svg" key="close-icon" color="white"/>
          </button>
        </div>
        <div v-if="isImage" class="image-viewer-container">
          <img :src="fileUrl" alt="file preview" 
            class="image-viewer" 
            :style="imageStyle" 
            draggable="false" />
        </div>
  
        <div v-if="isPdf" ref="pdfViewer" class="pdf-viewer">
            <iframe height="100%" width=100% :src="fileUrl" ></iframe>
        </div>
        <div v-if="isAudio">
          <audio controls>
            <source :src="fileUrl" type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div v-if="isVideo">
          <video controls>
            <source :src="fileUrl" type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
        <div v-if="isMarkdown" class="pdf-viewer">
          <iframe height="100%" width=100% :src="fileUrl" ></iframe>
        </div>
        <div v-if="isText">
          Texte
        </div>
      </div>
    </div>
  </template>
  <script>
  import VgIcon from "src/components/Vg/VgIcon.vue";
  export default {
    name: "vg-file-viewer",
    components: {
      VgIcon,
    },
    props: {
      file: { 
        type: File, 
        required: true 
      },
      allowPreviousNavigation:{
        type: Boolean,
        default: false
      },
      allowNextNavigation:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fileUrl : null
      };
    },
    computed: {
      isImage() {
        return this.file.type.startsWith("image/");
      },
      isPdf() {
        return this.file.type === "application/pdf";
      },
      isAudio(){
        return this.file.type.startsWith("audio/");
      },
      isVideo(){
        return this.file.type.startsWith("video/");
      },
      isMarkdown(){
        return this.getFileExtension( ) === "md";
      },
      isText(){
        return this.file.type.startsWith("text/");
      }
    },
    watch: {
      file: "loadFile",
    },
    methods: {
      getFileExtension() {
        return this.file.name.split(".").pop();
      },
      handleOverlayClick(event) {
        // Close the overlay if the click is on the overlay background
        if (event.target.classList.contains("overlay")) {
            this.closeViewer();
        }
      },
      closeViewer() {
        this.$emit("close");
      },
      openInNewTab(){
        window.open(this.fileUrl, '_blank');
      },
      async loadFile() {
        this.fileUrl = URL.createObjectURL(this.file);
      },
    },
    mounted() {
      this.loadFile();
    },
    beforeUnmount() {
      // Revoke Blob URL to free up memory when component is destroyed
      if (this.fileUrl) URL.revokeObjectURL(this.fileUrl);
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:700;
    pointer-events: none;
  }

  .overlay-content {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:800;
    pointer-events: none;
  }

  .image-viewer-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:900;
    pointer-events: auto;
  }

  .image-viewer {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain;
    pointer-events: auto;
    z-index:900;
  }

  .actions-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    z-index:980;
    pointer-events: auto;
  }

  .action-btn {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-btn:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  
  .pdf-viewer{
    width: 80vw;
    height: 90vh;
    pointer-events: auto;
  }
  .pdf-viewer canvas {
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto;
  }
  </style>
  