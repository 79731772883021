var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SharedLinks from './SharedLinks';
import Auth from './Auth';
import Lieux from './Lieux';
import Equipements from "./Equipements";
var VGSDK = /** @class */ (function () {
    function VGSDK(configOptions) {
        this.config = __assign({}, configOptions);
        this.auth = new Auth(this.config);
        // Lazy instantiation of services
        this._sharedLinks = null;
        console.log('VGSDK initialized', this.config);
    }
    Object.defineProperty(VGSDK.prototype, "sharedLinks", {
        get: function () {
            if (!this._sharedLinks) {
                this._sharedLinks = new SharedLinks(this.auth, this.config.apiBaseUrl);
            }
            return this._sharedLinks;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VGSDK.prototype, "lieux", {
        get: function () {
            if (!this._lieux) {
                this._lieux = new Lieux(this.auth, this.config.apiBaseUrl);
            }
            return this._lieux;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VGSDK.prototype, "equipements", {
        get: function () {
            if (!this._equipements) {
                this._equipements = new Equipements(this.auth, this.config.apiBaseUrl);
            }
            return this._equipements;
        },
        enumerable: false,
        configurable: true
    });
    return VGSDK;
}());
export default VGSDK;
