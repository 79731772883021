<template>
    <i :class="['vg-icon', 'svg-icon-'+size, {'rotate': rotate}]" :ref="ref" @click="$emit('click')"></i>
    <!--div style="display:flex;justify-content:flex-start;gap:10px;">
        SVG by convertio ->
        <i class="svg-icon-xs1"></i>
        <i class="svg-icon-lg1"></i>
        SVG by cedric ->
        <i class="svg-icon-xs"></i>
        <i class="svg-icon-lg"></i>
        PNG ->
        <i class="png-icon-xs"></i>
        <i class="png-icon-lg"></i>
        <img :src="'/static/assets/icone/composant_svg/ecran-ordinateur.svg'"
            style="filter: invert(40%) sepia(84%) saturate(1478%) hue-rotate(163deg) brightness(98%) contrast(101%);"/>
        <svg style="filter: invert(40%) sepia(84%) saturate(1478%) hue-rotate(163deg) brightness(98%) contrast(101%);">
            <image xlink:href="/static/assets/icone/composant_svg/ecran-ordinateur.svg" x="0" y="0" height="100" width="100" />
        </svg>
        <svg>
            <image xlink:href="/static/assets/icone/composant_svg/ecran-ordinateur.svg" x="0" y="0" height="100" width="100" style="fill:red;stroke: red;" />
        </svg>
    </div-->
</template>

<script>
const DEFAULT_ICON_COLOR = "#575656";
export default {
  name: "vg-icon",
    props: {
        url: {
            type: String,
            required: true
        },
        /**
        * xs md lg
        */
        size:{
            type: String,
            default: "md"
        },
        ref:{
            type: String,
            default: parseInt(Math.random()* (999999 - 1) + 1)
        },
        color:{
            type: String,
            default: null
        },
        rotate: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            iconUrl: null
        };
    },
    watch:{
        url:{
            handler: function(val){
                this.getIconSVG();
            }
        },
        color:{
            handler: function(newcolor){
                this.setIconMask();
            }
        }
    },
    computed: {

    },
    methods:{
        getIconSVG: function(){
            if(this.url.search(".png")){
                let iconPNG = this.url;
                let iconSVG = iconPNG.replace("/composant/", "/composant_svg/");
                iconSVG = iconSVG.replace(".png", ".svg");
                this.iconUrl = iconSVG;
            }else{
                this.iconUrl = this.url;
            }
        },
        setIconMask: function(){
            if(this.iconUrl){
                this.$refs[this.ref].style.setProperty("-webkit-mask-image", "url("+this.iconUrl+")");
                this.$refs[this.ref].style.setProperty("-mask-image", "url("+this.iconUrl+")");
            }
            this.$refs[this.ref].style.setProperty("background-color", this.color?this.color:DEFAULT_ICON_COLOR);
        }
    },
    created: function(){
        this.getIconSVG();
    },
    mounted: function(){
        this.setIconMask();
    }
};
</script>

<style lang="scss" scoped>
.vg-icon{
    display: block;
}
.svg-icon-18{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 18px;
    height: 18px;
}
.svg-icon-20{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.svg-icon-25{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}
.svg-icon-30{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 30px;
    height: 30px;
}
.svg-icon-xxs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 12px;
    height: 12px;
}
.svg-icon-xs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 15px;
    height: 15px;
}
.svg-icon-md{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.svg-icon-lg{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}
.png-icon-xs{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 15px;
    height: 15px;
}
.png-icon-md{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 20px;
    height: 20px;
}
.png-icon-lg{
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 25px;
    height: 25px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotate 0.8s linear infinite;
}
</style>
