<template>
	<div v-if="isEquipements" class="vg-search-datas-viewer">
		<!--span class="title">{{ $t("equipements") }} <small>({{ counters.equipements }})</small></span-->
		<div class="section-header" v-if="showEmpty || (counters.equipements && counters.equipements!=0)">
			<div class="section-title" @click="isEquipementsCollapsed=!isEquipementsCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isEquipementsCollapsed, 'fa-chevron-up': isEquipementsCollapsed}]"></i>
				<span class="title">{{ $t('equipements') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.equipements}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" 
				type="grey" 
				:disabled="!can('EQUIPEMENT.CREATE')" 
				icon="add"
				@click="$emit('create')"> 
				{{ $t("creer") }}
			</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.equipements<=5" v-show="!isEquipementsCollapsed">
			<div v-for="(equipement, index) in value" :key="equipement.id"
				style="display: flex;justify-content: space-between;align-items: center;gap:5px;width:100%;">
				<div :class="['viewer-item',{'disabled':!can('EQUIPEMENT.PAGE')}]">
					<div class="item" @click="onClickItem('EQUIPEMENT.PAGE', equipement)">
						<div class="item-content">
							<div class="title">
								<img :src="equipement.categorie_icon"/> 
								<b>{{ equipement.libel_equipement }}</b> 
								<b>{{ $t("dans") }} {{ equipement.lieu_libel_lieu }}</b>
							</div> 
							<small class="subtitle">{{ $t("equipement-numero", {qrCode: equipement.qrCode}) }}</small>
						</div>
						<div class="item-icon">
							<i class="fas fa-chevron-right"></i>
							<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
								:key="'equipement-item-'+equipement.id" /-->
						</div>
					</div>
					<div class="btns">
						<vg-button v-if="$app.role!='ROLE_BASIC' && $app.role!='ROLE_CHEF_EQUIPE'" 
							type="success-link" 
							:disabled="!can('EQUIPEMENT.CREATE_MAINTENANCE')" 
							icon="add"
							@click="$emit('create-fm', equipement)">{{ $t('creer-fm') }}</vg-button>
						<vg-button v-if="$app.role!='ROLE_BASIC' && $app.role!='ROLE_CHEF_EQUIPE'" type="danger-link" :disabled="!can('EQUIPEMENT.UPDATE')" 
							icon="edit"
							@click="$emit('update', equipement)">{{ $t('update') }}</vg-button>
						<vg-button v-if="$app.role=='ROLE_BASIC'" type="success" :disabled="!can('DEMANDE_INTERVENTION.PAGE')" 
							icon="add"
							@click="$emit('demande-intervention', equipement)">{{ $t('demande-intervention') }}</vg-button>
						<vg-button v-if="can('RECHERCHE_GENERALE.CREATE_SIGNALEMENT')" type="danger-link" :disabled="!can('DEMANDE_INTERVENTION.PAGE')" 
							@click="$emit('demande-intervention', equipement)"
							icon="add">{{ $t('signaler') }}</vg-button>
						<vg-button v-if="can('RECHERCHE_GENERALE.CREATE_CHECK') && hasTaches(equipement)" type="info-link" :disabled="!can('CHECKS.PAGE')" 
							icon="check"
							@click="$emit('check', equipement)">{{ $t('check') }}</vg-button>
					</div>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isEquipementsCollapsed"
			@click="onClickGroup('EQUIPEMENTS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('EQUIPEMENTS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/equipement.png" />
							<b>{{$t("equipements")}} ({{ counters.equipements }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isLieux" class="vg-search-datas-viewer">
		<!--span class="title">{{ $t("lieux") }} <small>({{ counters.lieux }})</small></span-->
		<div class="section-header" v-if="showEmpty || (counters.lieux && counters.lieux!=0)">
			<div class="section-title" @click="isLieuxCollapsed=!isLieuxCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isLieuxCollapsed, 'fa-chevron-up': isLieuxCollapsed}]"></i>
				<span class="title">{{ $t('lieux') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.lieux}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" 
				icon="add" :disabled="!can('PIECE.CREATE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.lieux<=5" v-show="!isLieuxCollapsed">
			<div v-for="(lieu, index) in value" :key="lieu.id"
				:class="['viewer-item',{'disabled':!can('PIECE.PAGE')}]">
				<div class="item" @click="onClickItem('PIECE.PAGE', lieu)">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/lieu.png"/> 
							<b>{{ lieu.libel_lieu }}</b>
						</div> 
						<small class="subtitle">{{ $t("lieu-path", {site: lieu.path.split("/")[1], batiment: lieu.path.split("/")[2], etage: lieu.path.split("/")[3]}) }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button v-if="$app.role!='ROLE_CHEF_EQUIPE'"  type="info-link" 
						icon="add" :disabled="!can('DEMANDE_INTERVENTION.PAGE')" @click="$emit('demande-intervention', lieu)">{{ $t('demande-intervention') }}</vg-button>
					<vg-button v-if="can('RECHERCHE_GENERALE.UPDATE')"  type="danger-link" 
						icon="edit" :disabled="!can('PIECE.UPDATE')" @click="$emit('update', lieu)">{{ $t('update') }}</vg-button>
					<vg-button v-if="can('RECHERCHE_GENERALE.CREATE_SIGNALEMENT')"  type="danger-link" :disabled="!can('DEMANDE_INTERVENTION.PAGE')" 
						 icon="add"
						@click="$emit('demande-intervention', lieu)">{{ $t('signaler') }}</vg-button>
					<vg-button v-if="can('RECHERCHE_GENERALE.CREATE_CHECK') && hasTaches(lieu)"  type="danger-link" :disabled="!can('CHECKS.PAGE')" 
						icon="check"
						@click="$emit('check', lieu)">{{ $t('check') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isLieuxCollapsed"
			@click="onClickGroup('LIEUX.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('LIEUX.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/lieu.png" />
							<b>{{ $t("lieux")}} ({{ counters.lieux }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isMaintenances" class="vg-search-datas-viewer">
		<!--span class="title">{{ $t("maintenances") }} <small>({{ counters.maintenances }})</small></span-->
		<div class="section-header" v-if="showEmpty || (counters.maintenances && counters.maintenances!=0)">
			<div class="section-title" @click="isMaintenancesCollapsed=!isMaintenancesCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isMaintenancesCollapsed, 'fa-chevron-up': isMaintenancesCollapsed}]"></i>
				<span class="title">{{ $t('maintenances') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.maintenances}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
				:disabled="!can('DEMANDE_INTERVENTION.PAGE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.maintenances<=5" v-show="!isMaintenancesCollapsed">
			<div v-for="(maintenance, index) in value" :key="maintenance.id"
				:class="['viewer-item',{'disabled':!can('MAINTENANCE.PAGE')}]">
				<div class="item" @click="onClickItem('MAINTENANCE.PAGE', maintenance)">
					<div class="item-content">
						<div class="title">
							<img v-if="maintenance.statut=='Resolue' || maintenance.statut=='Supervisor'" src="/static/assets/icone/maintenance-verte.png"/> 
							<img v-else src="/static/assets/icone/maintenance-rouge.png"/> 
							<vg-fichesav-description :fm="formatMaintenance(maintenance)" 
								:showIcon="false"
								inline />
						</div> 
						<small class="subtitle">{{ $t("maintenance-numero", {id: maintenance.id}) }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button  type="info-link" icon="edit" :disabled="!can('MAINTENANCE.ACTIONS')" 
						@click="$emit('open-actions', maintenance)">{{ $t('actions') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isMaintenancesCollapsed"
			@click="onClickGroup('MAINTENANCES.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('MAINTENANCES.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/maintenance.png" />
							<b>{{ $t("maintenances")}} ({{ counters.maintenances }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isContrats" class="vg-search-datas-viewer">
		<!--span class="title">{{ $t("maintenances") }} <small>({{ counters.maintenances }})</small></span-->
		<div class="section-header" v-if="showEmpty || (counters.contrats && counters.contrats!=0)">
			<div class="section-title" @click="isContratsCollapsed=!isContratsCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isContratsCollapsed, 'fa-chevron-up': isContratsCollapsed}]"></i>
				<span class="title">{{ $t('contrats') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.contrats}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
				:disabled="!can('CONTRAT.CREATE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.contrats<=5" v-show="!isContratsCollapsed">
			<div v-for="(contrat, index) in value" :key="contrat.id"
				:class="['viewer-item',{'disabled':!can('CONTRAT.PAGE')}]">
				<div class="item" @click="onClickItem('CONTRAT.PAGE', contrat)">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/contrat.png"/> 
							<b>{{ contrat.name }}</b>
						</div> 
						<small class="subtitle">{{ $t("contrat-tiers", {tiers: contrat.tiers_name}) }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button  type="danger-link" icon="edit" :disabled="!can('CONTRAT.UPDATE')" @click="$emit('update', contrat)">{{ $t('update') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isContratsCollapsed"
			@click="onClickGroup('CONTRATS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('CONTRATS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/contrat.png" />
							<b>{{ $t("contrats")}} ({{ counters.contrats }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isTiers" class="vg-search-datas-viewer">
		<!--span class="title">{{ $t("maintenances") }} <small>({{ counters.maintenances }})</small></span-->
		<div class="section-header" v-if="showEmpty || (counters.tiers && counters.tiers!=0)">
			<div class="section-title" @click="isTiersCollapsed=!isTiersCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isTiersCollapsed, 'fa-chevron-up': isTiersCollapsed}]"></i>
				<span class="title">{{ $t('tiers') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.tiers}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
				:disabled="!can('TIER.CREATE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.tiers<=5" v-show="!isTiersCollapsed">
			<div v-for="(tier, index) in value" :key="tier.id"
				:class="['viewer-item',{'disabled':!can('TIER.PAGE')}]">
				<div class="item" @click="onClickItem('TIER.PAGE', tier)">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/tiers.png"/> 
							<b>{{ tier.name }}</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button  type="danger-link" icon="edit" :disabled="!can('TIER.UPDATE')" @click="$emit('update', tier)">{{ $t('update') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isTiersCollapsed"
			@click="onClickGroup('TIERS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('TIERS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/tiers.png" />
							<b>{{ $t("tiers")}} ({{ counters.tiers }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isCategories" class="vg-search-datas-viewer">
		<div class="section-header" v-if="showEmpty || (counters.categories && counters.categories!=0)">
			<div class="section-title" @click="isCategoriesCollapsed=!isCategoriesCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isCategoriesCollapsed, 'fa-chevron-up': isCategoriesCollapsed}]"></i>
				<span class="title">{{ $t('categories') }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.categories}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
			:disabled="!can('CATEGORIE_EQUIPEMENT.CREATE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.categories<=5" v-show="!isCategoriesCollapsed">
			<div v-for="(categorie, index) in value" :key="categorie.id"
				:class="['viewer-item',{'disabled':!can('CATEGORIES_EQUIPEMENTS.PAGE')}]">
				<div class="item" @click="onClickItem('CATEGORIES_EQUIPEMENTS.PAGE', categorie)">
					<div class="item-content">
						<div class="title">
							<img :src="categorie.icon"/> 
							<b>{{ categorie.libelleCatgorie }}</b>
						</div> 
						<small class="subtitle">{{ $t("categorie") }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button  type="danger-link" icon="edit" :disabled="!can('CATEGORIE_EQUIPEMENT.UPDATE')" @click="$emit('update', categorie)">{{ $t('update') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isCategoriesCollapsed"
			@click="onClickGroup('CATEGORIES_EQUIPEMENTS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('CATEGORIES_EQUIPEMENTS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/categorie-equipement.png" />
							<b>{{ $t("categories")}} ({{ counters.categories }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isInterventions" class="vg-search-datas-viewer">
		<div class="section-header" v-if="showEmpty || (counters.interventions && counters.interventions!=0)">
			<div class="section-title" @click="isInterventionsCollapsed=!isInterventionsCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isInterventionsCollapsed, 'fa-chevron-up': isInterventionsCollapsed}]"></i>
				<span class="title">{{ $t('interventions') }}</span>
				<span v-if="!showEmpty" class="counter">{{ counters.interventions }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.interventions}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
				:disabled="!can('INTERVENTION.CREATE')" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.interventions<=5" v-show="!isInterventionsCollapsed">
			<div v-for="(intervention, index) in value" :key="intervention.id"
				:class="['viewer-item',{'disabled':!can('INTERVENTIONS.PAGE')}]">
				<div class="item" @click="onClickItem('INTERVENTION.PAGE', intervention)">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/intervention.png"/> 
							<b>{{ intervention.intitule }}</b>
						</div> 
						<small class="subtitle">{{ $t("intervention", {numero: intervention.numeroIntervention}) }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button  type="danger-link" icon="edit" :disabled="!can('INTERVENTION.UPDATE')" @click="$emit('update', intervention)">{{ $t('update') }}</vg-button>
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isInterventionsCollapsed"
			@click="onClickGroup('INTERVENTIONS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('INTERVENTIONS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/intervention.png" />
							<b>{{ $t("interventions")}} ({{ counters.interventions }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isContacts" class="vg-search-datas-viewer">
		<div class="section-header" v-if="showEmpty || (counters.contacts && counters.contacts!=0)">
			<div class="section-title" @click="isContactsCollapsed=!isContactsCollapsed">
				<i v-if="!showEmpty" :class="['fas', {'fa-chevron-down': !isContactsCollapsed, 'fa-chevron-up': isContactsCollapsed}]"></i>
				<span class="title">{{ $t('contacts') }}</span>
				<span v-if="!showEmpty" class="counter">{{ counters.contacts }}</span>
			</div>
			<small v-if="!showEmpty">{{ $t("nb-resultats", {nb: counters.contacts}) }}</small>
			<!--vg-button v-if="can('RECHERCHE_GENERALE.CREATE')" size="sm" type="grey" icon="add" 
				:disabled="!can('CONTACT.CREATE') || true" @click="$emit('create')">{{ $t("creer") }}</vg-button-->
		</div>
		<div class="viewer-container" v-if="!showEmpty && counters.contacts<=5" v-show="!isContactsCollapsed">
			<div v-for="(contact, index) in value" :key="contact.id"
				:class="['viewer-item',{'disabled':!can('CONTACTS.PAGE')}]">
				<div class="item" @click="onClickItem('TIER.PAGE', contact)">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/contact.png"/> 
							<b>{{ intervention.intitule }}</b>
							<b v-if="contact.firstname">{{ contact.firstname }}</b>
							<b v-if="contact.lastname">{{ contact.lastname }}</b>
							<small v-if="contact.email">{{ contact.email }}</small>
						</div> 
						<small class="subtitle">{{ $t("contact") }}</small>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<!--vg-button  type="danger-link" :disabled="!can('CONTACT.UPDATE')" @click="$emit('update', contact)">{{ $t('update') }}</vg-button-->
				</div>
			</div>
		</div>
		<div class="viewer-container" v-else-if="!showEmpty" v-show="!isContactsCollapsed"
			@click="onClickGroup('CONTACTS.PAGE')">
			<div :class="['viewer-item',{'disabled':!can('CONTACTS.PAGE')}]">
				<div class="item">
					<div class="item-content">
						<div class="title">
							<img src="/static/assets/icone/contact.png" />
							<b>{{ $t("contacts")}} ({{ counters.contacts }})</b>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isEquipementsOuLieux" class="vg-search-datas-viewer">
		<div class="viewer-container" v-if="!showEmpty">
			<div v-for="(equipement, index) in value" :key="equipement.id" class="viewer-item-lg">
				<div class="item">
					<div class="item-content">
						<div class="title" v-if="equipement.isGroupEqp=='1'">
							<img src="/static/assets/icone/equipement.png"/> 
							<b>{{ equipement.libel_lieu }}</b>
						</div>
						<div class="title" v-else>
							<img :src="equipement.categorie_icon"/> 
							<span>{{ equipement.libel_equipement }}</span>
						</div>
					</div>
					<div class="item-icon">
						<i class="fas fa-chevron-right"></i>
						<!--vg-icon url="static/assets/icone/svg/chevron-right.svg" color="#AFB6BF" size="18"
							:key="'equipement-item-'+equipement.id" /-->
					</div>
				</div>
				<div class="btns">
					<vg-button v-if="hasTaches(equipement)" type="danger-link" style="width:30%;" 
						icon="check" 
						:disabled="!can('CHECKS.PAGE')" 
						@click="check(equipement)">{{ $t('check') }}</vg-button>
					<span v-if="hasTaches(equipement)">{{ $t("ou") }}</span>
					<vg-button type="danger-link" style="width:20%;"
						icon="add" 
						:disabled="!can('DEMANDE_INTERVENTION.PAGE')" 
						@click="signaler(equipement)">{{ $t('signaler') }}</vg-button>
				</div>
			</div>
			<!--div style="display: flex;justify-content: space-between;align-items: center;gap:10px;width:100%;">
				<vg-button type="grey" style="width:40%;">{{ $t("previous") }}</vg-button>
				<vg-button type="grey" style="width:40%;">{{ $t("next") }}</vg-button>
			</div-->
		</div>
	</div>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgIcon from "src/components/Vg/VgIcon.vue";
	import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
	
	import { mapGetters } from "vuex";

    export default {
        name: 'vg-search-datas-viewer',
		i18n:    { 
			"locale":navigator.language,
			"messages": {
				"fr": {
					"equipements": "Equipements",
					"equipement-numero": "Equipement - QrCode: {qrCode}",
					"lieux": "Lieux",
					"lieu-path": "Lieu - Localisation: {site} / {batiment} / {etage}",
					"maintenances": "Fiches curatives",
					"maintenance-numero": "Fiche curative - ID: {id}",
					"contrats": "Contrats",
					"contrat-tiers": "Contrat - Tiers: {tiers}",
					"tiers": "Tiers",
					"tiers-type": "Tiers - Type: {type}",
					"categories": "Catégories",
					"categorie": "Catégorie équipement",
					"interventions": "Interventions",
					"intervention": "Intervention - N°: {numero}",
					"contacts": "Contacts",
					"maintenance": "Fiche n°",
					"creer-fm": "Créer fiche curative",
					"update": "Modifier",
					"demande-intervention": "Demande intervention",
					"signaler": "Signaler",
					"check": "Valider tâche",
					"ou": "ou",
					"creer": "Créer",
					"nb-resultats": "{nb} résultats"
				},
				"en": {
					"equipements": "Equipments",
					"equipement-numero": "Equipment - QrCode: {qrCode}",
					"lieux": "Rooms",
					"lieu-numero": "Room - Location: {site} / {batiment} / {etage}",
					"maintenances": "Curative sheets",
					"maintenance-numero": "Curative sheet - ID: {id}",
					"contrats": "Contracts",
					"contrat-tiers": "Contract - Suppliers: {tiers}",
					"tiers": "Suppliers",
					"categories": "Categories",
					"categorie": "Equipment category",
					"interventions": "Interventions",
					"intervention": "Intervention - N°: {numero}",
					"contacts": "Contacts",
					"maintenance": "Sheet n°",
					"creer-fm": "create curative sheet",
					"update": "Update",
					"demande-intervention": "Intervention request",
					"signaler": "Report",
					"check": "Validate task",
					"ou": "or",
					"creer": "Create"
				}
			}
		},
		components: {
			VgInput,
			VgButton,
			VgIcon,
			VgFichesavDescription
		},
		mixins: [],
        props: {
			value: {
				type: Array,
				default: function(){
					return [];
				}
			},
			searchValue: {
				type: String,
				default: null
			},
			showEmpty: {
				type: Boolean,
				default: false
			},
			isEquipements:{
				type: Boolean,
				default: false
			},
			isLieux:{
				type: Boolean,
				default: false
			},
			isMaintenances:{
				type: Boolean,
				default: false
			},
			isContrats:{
				type: Boolean,
				default: false
			},
			isTiers:{
				type: Boolean,
				default: false
			},
			isCategories:{
				type: Boolean,
				default: false
			},
			isInterventions:{
				type: Boolean,
				default: false
			},
			isContacts:{
				type: Boolean,
				default: false
			},
			isEquipementsOuLieux:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				isEquipementsCollapsed: false,
				isLieuxCollapsed: false,
				isMaintenancesCollapsed: false,
				isContratsCollapsed: false,
				isTiersCollapsed: false,
				isCategoriesCollapsed: false,
				isInterventionsCollapsed: false,
				isContactsCollapsed: false
            };
        },
		methods: {
			signaler: function(equipement){
				if(equipement.isGroupEqp=='1'){ 
					this.$emit('demande-intervention', {
						id:equipement.idLieu_id, 
						codeUn: equipement.codeUn, 
						libel_lieu: equipement.libel_lieu, 
						equipement_id: equipement.id,
						idCategorie_id: equipement.idCategorie_id
					});
				}else{ 
					this.$emit("demande-intervention", {
						id: equipement.id,
						libel_equipement: equipement.libel_equipement,
						qrCode: equipement.qrCode
					});
				}
			},
			check: function(equipement){
				if(equipement.isGroupEqp=='1'){ 
					this.$emit('check', {
						id:equipement.idLieu_id, 
						codeUn: equipement.codeUn, 
						libel_lieu: equipement.libel_lieu, 
						equipement_id: equipement.id,
						idCategorie_id: equipement.idCategorie_id
					});
				}else{ 
					this.$emit("check", {
						id: equipement.id,
						libel_equipement: equipement.libel_equipement,
						qrCode: equipement.qrCode
					});
				}
			},
			onClickItem: function(rule, item){
				if(this.can(rule)) this.$emit("open", item);
			},
			onClickGroup: function(rule){
				if(this.can(rule)) this.$emit('redirect');
			},
			formatMaintenance: function(maintenance){
				return Object.assign({}, maintenance, {
					composant: {
						libelComposant: maintenance.composant_libelComposant, 
						icon: maintenance.composant_icon
					},
					equipement: {
						isGroupEqp: maintenance.equipement_isGroupEqp,
						libel_equipement: maintenance.equipement_libel_equipement,
						categorie_icon: maintenance.categorie_icon
					}
				});
			},
			hasTaches: function(entity){
				if(this.taches) return this.taches.findIndex((tache)=>tache.idCategorie_id==entity.idCategorie_id)!=-1;
				else return false;
			}
		},
		computed: {
			...mapGetters({
				counters: "SearchDatasStore/getCounters",
				taches: "SearchDatasStore/getTaches"
			})
		}
    };

</script>
<style lang="scss" scoped>
.vg-search-datas-viewer{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.section-header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
		border-bottom: 1px solid #e5e7eb;
		background-color: #F9FAFB;
		span{
			
			color: #4B5563;
		}
		small{
			
			color: #6B7280;
		}
		.section-title{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5px;
		}
	}
	.viewer-container{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 5px;
		font-family: ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
		.viewer-item{
			cursor: pointer;
			width: 100%;
			padding: 8px 5px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 5px;
			font-size: 12px;
			.item{
				width:100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 5px;
				.item-content{
					flex-grow: 5;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					gap: 5px;
					.title{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						gap: 3px;
						font-weight: bold;
						color: rgb(17 24 39/var(--tw-text-opacity));
						img{
							width: 20px;
							height: 20px;
							margin-right:4px;
						}
					}
					.subtitle{
						font-size: 12px;
						color: #6B7280;
					}
				}
				.item-icon{
					width: 30px;
					flex-grow: 1;
					color: #6B7280;
					font-weight: normal;
				}
			}
			.btns{
				width:100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 15px;
				padding-left: 15px;
				background-color: rgb(249 250 251);
				border: 1px solid #e5e7eb;
			}
		}
		.viewer-item-lg{
			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 5px;
			font-size: 14px;
			.item{
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;
				img{
					width: 25px;
					height: 25px;
				}
			}
			.btns{
				width: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 20px;
			}
		}
		.disabled{
			cursor: default !important;
			color: #ccc !important;
		}
		.disabled:hover{
			background-color: white !important;
		}
		.viewer-item:hover{
			background-color: whitesmoke;
		}
	}
}
</style>
