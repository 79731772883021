<template lang="html">
    <div class="affectation-fm-cell-render" v-if="params.data.affectation">
        <div class="affectation" v-for="affecte,index in params.data.affectation.affectes" :key="index">
            <img :src="affecte.user_id?'/static/assets/icone/user.png':'/static/assets/icone/tiers.png'"/>
            <span v-if="affecte.user_id">{{affecte.user_name}}</span>
            <span v-else>{{affecte.name}}</span>
            <small v-if="getDureeAffectation">{{ getDureeAffectation }}</small>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import LangMixins from "src/components/Vg/TagGrid/LangMixins";
export default Vue.extend({
    name:"fm-cell-render",
    mixins:[LangMixins],
    props:{
        params:{
            type: Object,
            required: true
        }
    },
    computed:{
        getDureeAffectation: function(){
            if(this.params.data.affectation.start && this.params.data.affectation.end){ 
                let start = moment(this.params.data.affectation.start);
                let end = moment(this.params.data.affectation.end);
                let duree = moment.duration(end.diff(start));
                return duree.humanize();
            }else{ 
                return null;
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.affectation-fm-cell-render{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    .affectation{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap:5px;
        img{
            width: 15px;
            height: 15px;
        }
    }
}
</style>
 }