<template >
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getIconEquipements"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :globalFilters="hasActiveGlobalFilters"
        :filtersTitle="$t('modal-filters-title')"
        :isLoadingState="isLoading"
        :contactSupportToImportDatas="false"
        :titleImportationDonneesModal="$t('importer-modele-integration')"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange"
        @global-filters-remove="deleteGlobalFilters"
        @importation-donnees-file-change="handleImportEquipementModel">
        <template #create-button>
            <vg-button 
                type="success"
                @click="handleClickRecenserEquipement">
                {{$t("create-equipement")}}
            </vg-button>
        </template>

        <template #panel-menu-more>
            <vg-button  @click="scanQrCode">{{ $t("menuMore-scanEquip") }}</vg-button>
            <vg-button
                type="success"
                @click="handleClickRecenserEquipement">
                {{$t("inventory-equipement")}}
            </vg-button>
        </template>
        <vg-equipement-form
            v-if="displayEquipementForm"
            @close="displayEquipementForm = false"
        >
        </vg-equipement-form>
        <template #action-button-item >
            <li @click="openEquipementsRapport">{{$t("menu-more-rapport-asset")}}</li>
            <li @click="handleCreateRapportPdf">{{$t("menu-more-export-pdf")}}</li>
            <li @click="handleExportEquipementIntegrationModel">{{$t("telecharger-modele-integration")}}</li>
        </template>
        <template #panel-widget-lieux>
          <vg-tree-lieux
            @select-node="handleSelectNode"
            @select-piece="handleSelectNode"
          />
        </template>
        <template #panel-filters-attributs>
            <vg-input
                :title="$t('etiquetage')"
            >
                <vg-equipement-etiquetage-selector
                    v-model="agFilters.field_etiqueter.value"
                >
                </vg-equipement-etiquetage-selector>
            </vg-input>
            <vg-checkbox v-model="agFilters.field_isHorsService.value" :label="$t('is-hors-service')" />

            <vg-input
                :title="$t('filtre-caracteristiques-techniques')"
                @delayed-input="(filter)=>agFilters.field_caracteristiques.value = filter"
                
            >
            </vg-input>
            <vg-input
                :title="$t('filtre-numero-serie')"
                @delayed-input="(filter)=>agFilters.field_num_serie.value = filter"
            >
            </vg-input>
            <vg-input
                :title="$t('batch-number')"
                @delayed-input="(filter)=>agFilters.field_batchNumber.value = filter"
            >
            </vg-input>
            <vg-input :title="$t('Desktop-filtres-categorie')" >
                <vg-categorie-selector
                    v-model="agFilters.field_categorie.value"
                    :isGe="false"
                    :attributeValue="'libelleCatgorie'"
                >
                </vg-categorie-selector>
            </vg-input>
            <vg-input :title="$t('Desktop-filtres-tags')"
                v-model="agFilters.field_categorieTags.value"
                @delayed-input="(filter)=>agFilters.field_categorieTags.value = filter"
                >
            </vg-input>
        </template>
        <template #panel-filters-localisation>
            <vg-filter-site
                v-model="agFilters.field_path.value">
            </vg-filter-site>
        </template>

        <template #search>
            <vg-text-filter
                v-model="searchQuery">
            </vg-text-filter>
        </template>

        <template #header-bottom-left>
            <span v-if="selectedEquipments.length">
                {{$t("equipements-selected", {nEquipements: selectedEquipments.length})}}
            </span>
            <vg-button v-show="selectedEquipments.length && defaultTab=='equipement'" :type="'default'" style="margin-left:20px;" @click="isDeplacementsEquipementsFormDisplayed = true">
                {{$t("deplacer")}}
            </vg-button>
            <vg-button v-show="selectedEquipments.length && defaultTab!='sortis' && defaultTab!='sortis-temporaire'" :type="'default'"  @click="goToSortieTemporaireEqps">
                {{$t("sortie-temporaire")}}
            </vg-button>
            <vg-button v-show="selectedEquipments.length && defaultTab!='sortis'" :type="'default'"  @click="goToRetourEqps">
                {{$t("retour")}}
            </vg-button>
            <vg-button v-show="selectedEquipments.length" :type="'default'" @click="isUpdateEquipementsFormDisplayed = true">
                {{$t("update-equipments")}}
            </vg-button>
            <vg-button v-show="selectedEquipments.length" :type="'default'" @click="showCreateInterventionSansContrat=true;">
                {{$t("intervention-sans-contrat")}}
            </vg-button>
        </template>

        <template #primary>
            <div v-if="$vgutils.isMobile()" style="display: flex;justify-content: space-between;align-items: center;gap:10px;margin-bottom: 10px;">
                <vg-button v-if="!isModeSelectionEquipementMultiple" 
                    size="sm" type="info" style="width:100%;"
                    @click="isModeSelectionEquipementMultiple=true;">
                    {{ $t("btn-selection-equipements") }}
                </vg-button>
                <span v-if="isModeSelectionEquipementMultiple">{{ $t("x-equipements-selectionnes", {nEquipements: selectedEquipments.length}) }}</span>
                <vg-button v-if="isModeSelectionEquipementMultiple" 
                    size="sm" type="gray"
                    @click="isModeSelectionEquipementMultiple=false;">
                    {{ $t("btn-cancel-selection-equipements") }}
                </vg-button>
            </div>
            <div v-if="isModeSelectionEquipementMultiple && selectedEquipments.length" 
                style="display: flex;justify-content: flex-start;align-items: center;gap:10px;margin-bottom: 10px;">
                <vg-button v-if="defaultTab!='sortis' && defaultTab!='sortis-temporaire'" 
                    :type="'info'" 
                    @click="goToSortieTemporaireEqps">
                    {{$t("sortie-temporaire")}}
                </vg-button>
            </div>
            <vg-tabs v-model="defaultTab"
                :color="'gris'"
                :tabs="tabs"
                @tabClicked="onTabClicked">
            </vg-tabs>
            <vg-equipements-table v-if="!isEquipementsSortisFocused"
                :filters="agFilters"
                :showPrint="false"
                :isEquipementPicker="isModeSelectionEquipementMultiple"
                paginate
                key="vg-equipements-table"
                clearCollectionAfterDestroyed />
            <vg-equipements-table v-else
                :filters="agFilters"
                :showPrint="false"
                :isEquipementPicker="isModeSelectionEquipementMultiple"
                paginate
                :hiddenColumns="[]"
                :id="'vg-equipements-sortis-table'"
                key="vg-equipements-sortis-table"
                clearCollectionAfterDestroyed />
        </template>

        <template #secondary-filters>
            <vg-filter-service v-model="agFilters.field_service.value" />
            <vg-filter-sortie-type v-if="isEquipementsSortisFocused"
            v-model="agFilters.field_typeSortie.value"/>
            <vg-input :title="$t('Desktop-filtres-categorie')" >
                <vg-categorie-selector
                    v-model="agFilters.field_categorie.value"
                    :isGe="false"
                    :attributeValue="'libelleCatgorie'"
                    >
                </vg-categorie-selector>
            </vg-input>
            
             <!--vg-categorie-tags-datalist v-model="agFilters.categorieTags.value"
                :label="$t('Desktop-filtres-tags')"/-->
            <vg-collapse :title="$t('filtres-complementaires')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-checkbox v-model="agFilters.field_isHorsService.value" :label="$t('is-hors-service')" />
                    <vg-input
                        :title="$t('filtre-caracteristiques-techniques')"
                        @delayed-input="(filter)=>agFilters.field_caracteristiques.value = filter"

                    >
                    </vg-input>
                    <vg-input
                        :title="$t('filtre-numero-serie')"
                        @delayed-input="(filter)=>agFilters.field_num_serie.value = filter"
                    >
                    </vg-input>
                    <vg-input
                        :title="$t('batch-number')"
                        @delayed-input="(filter)=>agFilters.field_batchNumber.value = filter"

                    >
                    </vg-input>
                    <vg-filter-site
                        v-model="agFilters.field_path.value">
                    </vg-filter-site>
                    <vg-filter-statut-equipement
                        v-model="agFilters.field_statutEquipement.value"
                    >
                    </vg-filter-statut-equipement>
                    <vg-input
                        :title="$t('filtre-statut-equipement')"
                    >
                        <vg-tags-equipement-statut-selector
                            v-model="agFilters.field_statutVerification.value"
                            key="statut-selector" />
                    </vg-input>
                </template>
            </vg-collapse>
            <br>
        </template>
        <template #secondary-widget>
            <vg-tree-lieux
                v-model="agFilters.field_path.value" 
                @select-piece="handleSelectNode"
            />
            <vg-tree-categories style="margin-top:10px;"
                @excluded-tags="onExcludedCategorieTags"
                @select-node="selectCategorieTag"
                @remove-selected-node="onRemoveSelectedNode"
            />
        </template>

        <vg-modal
            v-if="showCodeScanner"
            :title="'qrCode'"
            @close="showCodeScanner=false"

            >
            <template v-slot:body>
                <zxing-scanner
                            @scanSuccess="filterTableByQrCode"
                            :isDemo="true"
                            :code="'qrcode'"
                ></zxing-scanner>
            </template>
            <template v-slot:footer>
                <br>
            </template>
        </vg-modal>
        <vg-deplacements-equipements-form
            v-if="isDeplacementsEquipementsFormDisplayed"
            @close="isDeplacementsEquipementsFormDisplayed = false"
            @save="handleSaveDeplacements" />
        <vg-update-equipements-form
            v-if="isUpdateEquipementsFormDisplayed"
            @close="isUpdateEquipementsFormDisplayed = false;"
            @save="afterUpdateEquipements" />
        <vg-intervention-form v-if="showCreateInterventionSansContrat"
            :isPeriodique="true"
            :defaultStatut="'intervention-realisee'"
            :defaultSelectedEquipements="selectedEquipments"
            isSansContrat
            @close="showCreateInterventionSansContrat=false;"
            @save="showCreateInterventionSansContrat=false;" 
            />
            <vg-modal
                v-if="openValidationExcelTableBeforeImport"
                width="90vw"
                :title="'Liste des équipements qui seront importés'"
                @close="openValidationExcelTableBeforeImport=false"
                >
                <template v-slot:body>
                    <vg-tabs v-model="tabImportEquipementValidation"
                        :color="'gris'"
                        :tabs="tabsImportEquipementValidation"
                        @tabClicked="onTabImportEquipementValidationClicked">
                    </vg-tabs>
                    <vg-excel-grid-editor 
                        v-model="equipementsToImport" 
                        :showOnlyNotValidRows="showOnlyequipementsToImportRequiredAttention"
                        :excelRowsConfiguration = "excelRowsConfiguraion"
                    >

                    </vg-excel-grid-editor>
                </template>
                <template v-slot:footer>
                    <vg-button
                        :type="'default'"
                        @click="openValidationExcelTableBeforeImport=false">
                        {{$t("cancel")}}
                    </vg-button>
                    <vg-button
                        :type="'success'"
                        @click="validationEquipementsAImporter">
                        {{ $t("valider-limportation") }}
                    </vg-button>
                </template>
            </vg-modal>
    </vg-app-layout>

</template>

<script>


import TagGrid from '../../components/Grid/TagGrid.vue';
import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';


import VgButton from 'src/components/Vg/VgButton.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgReportingPrints from 'src/components/Vg/TagGrid/VgReportingPrints.vue';
import VgCategoriesSelector from "src/components/Vg/Categorie/VgCategoriesSelector.vue";
import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgCategorieTagsDatalist from "src/components/Vg/Datalists/VgCategorieTagsDatalist.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import equipementsMixins from "src/mixins/equipementsMixins.js";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import FiltersMixins from 'src/mixins/FiltersMixins';

import VgGroup from "src/components/Vg/VgGroup.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgTreeCategories from "src/components/Vg/Categorie/VgTreeCategories.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";

import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";
import VgUpdateEquipementsForm from "src/components/Vg/Forms/VgUpdateEquipementsForm.vue";
import VgDeplacementsEquipementsForm from "src/components/Vg/Forms/VgDeplacementsEquipementsForm.vue";
import VgEquipementEtiquetageSelector from "src/components/Vg/Selectors/VgEquipementEtiquetageSelector.vue";
import VgFilterSortieType from "src/components/Vg/Filters/VgFilterSortieType.vue";
import VgFilterStatutEquipement from "src/components/Vg/Filters/VgFilterStatutEquipement.vue";
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
import VgTagsEquipementStatutSelector from 'src/components/Vg/Selectors/VgTagsEquipementStatutSelector.vue';
//import VueExcelEditor from 'vue-excel-editor'
import VgExcelGridEditor from 'src/components/Vg/ExcelGridEditor/VgExcelGridEditor.vue';
import Metadatas from "src/services/Metadatas.js";
import { EquipementExcelImporter } from 'src/Logic/EquipementExcelImporter';
import { mapGetters } from 'vuex';

export default {
    name: 'equipements',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Liste des équipements",
            "filtres-complementaires": "Filtres complémentaires",
            "Desktop-filtres-tags": "Filtrage par étiquette catégorie",
            "create-equipement": "Créer équipement(s)",
            "inventory-equipement": "Créer équipement",
            "collapse-filter": "Filtres complémentaires",
            "menu-more-export-pdf": "Export (.pdf)",
            "menu-more-gerer-columns": "Gérer les colonnes",
            "menu-more-export-xls": "Export (.xls)",
            "action-button": "Action",
            "menu-more-rapport-asset": "Rapport Assets",
            "menu-more-rapport": "Créer rapport",
            "filter-arborescence-title": "Arborescence",
            "btn-create-1": "Recenser equipement",
            "btn-create-2": "(action mobile)",
            "tab-equipement-actif": "Équipements actifs",
            "tab-equipement-sortis": "Équipements sortis",
            "tab-equipement-sortis-temporaire": "Équipements sortis temporairement",
            "collapse-filter-lieux": "Filtrage par lieux",
            "site": "Site",
            "batiment": "Batiment",
            "etage": "Etage",
            "piece": "piece",
            "menuMore-scanEquip": "Scanner équipement",
            "Desktop-filtres-categorie": "Filtrage par catégorie",
            "filtre-numero-serie": "Numéro de série",
            "filtre-caracteristiques-techniques": "Caractéristiques techniques",
            "etiquetage": "est étiqueté",
            "batch-number": "N° de Lot",
            "liste-equipements": "Liste-équipements",
            "update-equipments": "Modifier",
            "modal-filters-title": "Filtrer les équipements",
            "deplacer": "Déplacer",
            "telecharger-modele-integration": "Télécharger le modèle d'importation",
            "importer-modele-integration": "Importer équipements (excel)",
            "importer-modele-integration-confirm": "Confirmer l'importation des {nbEquipement} équipements",
            "equipements-selected": "{nEquipements} équipement(s) sélectionné(s)",
            "sortie-temporaire": "Sortie temporaire équipement(s)",
            "intervention-sans-contrat": "Créer intervention tiers préventive (sans contrat)",
            "retour": "Retour équipement(s)",
            "delete-global-filters": "Supprimer filtres recherche globale",
            "btn-selection-equipements": "Activer sélection multiple",
            "btn-cancel-selection-equipements": "Quitter",
            "x-equipements-selectionnes": "{nEquipements} équipements sélectionnés",
            "filtre-statut-equipement": "Statut équipement",
            "valider-limportation": "Valider les équipements à importés",
            "equipement-import-missing-fields": "Les champs suivant sont requis : {fields}",
            "is-hors-service": "Montrer les équipements hors service"
        },
        "en": {
            "site": "Site",
            "filtres-complementaires": "More filters",
            "batiment": "Block",
            "etage": "Level",
            "piece": "Room",
            "collapse-filter-lieux": "Filtering by location",
            "Desktop-filtres-tags": "Filtering by category tags",
            "menu-more-gerer-columns": "Manage columns",
            "menu-more-export-xls": "Export (.xls)",
            "action-button": "Action",
            "menu-more-rapport-asset": "Asset report",
            "menu-more-rapport": "Create report",
            "filter-arborescence-title": "Tree view",
            "btn-create-1": "List equipment",
            "btn-create-2": "(On-the-go action)",
            "tab-equipement-actif": "Equipment in use",
            "tab-equipement-sortis": "Equipment released",
            "collapse-filter": "Extra filters",
            "page-title": "Equipment list",
            "create-equipement": "Create equipment",
            "inventory-equipement": "Create equipment",
            "menu-more-export-pdf": "Export (.pdf)",
            "menuMore-scanEquip": "Scan équipment",
            "Desktop-filtres-categorie": "Filter by category",
            "filtre-numero-serie": "Serial N°",
            "filtre-caracteristiques-techniques": "Technical caractheristics",
            "etiquetage": "Is tagged",
            "batch-number": "Batch N°",
            "liste-equipements": "Equipments-list",
            "update-equipments": "Update",
            "modal-filters-title": "Filter equipments",
            "deplacer": "Move",
            "telecharger-modele-integration": "Download the integration template",
            "importer-modele-integration": "Import equipments file",
            "importer-modele-integration-confirm": "Confirm equipments import",
            "equipements-selected": "{nEquipements} equipment selected",
            "delete-global-filters": "Delete global search filters",
            "filtre-statut-equipement": "Equipment status",
            "valider-limportation": "Validate equipments to import",
            "equipement-import-missing-fields": "The following fields are required : {fields}",
            "is-hors-service": "Show out of service equipments"
        },
        "th": {
            "site": "หน้างาน",
            "batiment": "บล็อก",
            "etage": "ระดับ",
            "piece": "ห้อง",
            "collapse-filter-lieux": "การกรองตามที่ตั้ง",
            "menu-more-gerer-columns": "จัดการคอลัมน์",
            "menu-more-export-xls": "ส่งออก (.xls)",
            "action-button": "ปฏิบัติการ",
            "menu-more-rapport-asset": "รายงานสินทรัพย์",
            "menu-more-rapport": "สร้างรายงาน",
            "filter-arborescence-title": "มุมมองต้นไม้",
            "btn-create-1": "ทำรายการอุปกรณ์",
            "btn-create-2": "(ปฏิบัติการขณะเดินทาง)",
            "tab-equipement-actif": "อุปกรณ์ที่ใช้งาน",
            "tab-equipement-sortis": "อุปกรณ์ที่ปล่อยแล้ว",
            "collapse-filter": "ตัวกรองพิเศษ"
        }
    }
},
    mixins: [
        equipementsMixins,
        TagGridMixins,
        CategorieMixins,
        FiltersMixins
    ],
    components: {
        zxingScanner,
        VgInput,
        TagGrid,
        VgButton,
        VgTabs,
        VgTextFilter,
        VgCategoriesSelector,
        VgCategorieSelector,
        VgReportingPrints,
        VgGroup,
        VgFilterSite,
        VgTreeLieux,
        VgPagination,
        VgEquipementsTable,
        VgEquipementForm,
        VgInterventionForm,
        VgEquipementEtiquetageSelector,
        VgDeplacementsEquipementsForm,
        VgCollapse,
        VgUpdateEquipementsForm,
        VgCategorieTagsDatalist,
        VgTreeCategories,
        VgFilterSortieType,
        VgFilterService,
        VgFilterStatutEquipement,
        VgTagsEquipementStatutSelector,
        VgExcelGridEditor,
        VgCheckbox
    },
    props:{
        defaultFilters:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data: function() {
        return {
            agFilters: {
                libel_equipement: { attr: "e.libel_equipement",colId: "e.libel_equipement", value: null, action:"contains", openParenthesis: true},
                qrCode: { attr: "e.qrCode",colId: "e.qrCode", value: null, action:"contains",  logicalOperator: "OR"},
                carac_techniques: { attr: "e.carac_techniques",colId: "e.carac_techniques", value: null, action:"contains",  logicalOperator: "OR"},
                categorieTags: { attr: "c.tags",colId: "c.tags", value: null, action:"contains",  logicalOperator: "OR"},
                num_serie: { attr: "e.num_serie",colId: "e.num_serie", value: null, action:"contains", logicalOperator: "OR", closeParenthesis: true},
                field_path: { attr:"l.path",colId: "l.path", value: null, action:"contains" },
                field_type: { attr:"e.type",colId: "e.type", value: "Building", action:"not_equals" },
                field_num_serie: { attr: "e.num_serie",colId: "e.num_serie", value: null, action:"contains"},
                field_batchNumber: { attr: "e.batchNumber",colId: "e.batchNumber", value: null, action:"contains"},
                field_isGroupEqp: { attr: "e.isGroupEqp",colId: "e.isGroupEqp", value: "1", action:"not_equals"},
                field_categorie: { attr: "c.libelleCatgorie",colId: "c.libelleCatgorie", value: null, action:"equals"},
                field_categorieTags: { attr: "c.tags",colId: "c.tags", value: null, action:"start_with"},
                field_excludeCategorieTags_null: { attr: "c.tags", value: null, action:"is_null", openParenthesis: true},
                field_excludeCategorieTags: { attr: "c.tags", value: null, action:"not_start_with", logicalOperator: "OR", closeParenthesis: true},
                field_caracteristiques: { attr: "e.carac_techniques",colId: "e.carac_techniques", value: null, action:"contains"},
                field_sortie: { attr: "se.dateSortie", colId: "se.dateSortie", value: 1, action: "is_null"},
                field_etiqueter: { attr: "e.etiquetter", colId: "e.etiquetter", value: null, action: "equals"},
                field_statutEquipement: { attr: "e.statut", colId: "e.statut", value: null, action: "equals"},
                field_typeSortie: { attr: "se.type", value: null, action: "equals"},
                field_service: { attr: "l.service", value: null, action: "equals"},
                field_statutVerification: { attr: "e.statutVerification", value: null, action: "equals"},
                field_isHorsService: { attr: "e.isHorsService", value: null, action: "equals"},
            },
            selectedPiece:null,
            searchQuery: '',
            displayEquipementForm:false,
            isDeplacementsEquipementsFormDisplayed:false,
            isUpdateEquipementsFormDisplayed:false,
            showCodeScanner:false,
            isLoading:false,
            defaultTab: null,
            isModeSelectionEquipementMultiple: false,
            showCreateInterventionSansContrat: false,
            openValidationExcelTableBeforeImport:false,
            equipementsToImportAndAwaitingValidation:[],
            showOnlyequipementsToImportRequiredAttention:false,
            equipementsToImportRequiredAttentionQuantity:0,
            excelRowsConfiguraion:[
                {
                    field: "Localisation",
                    label: "Localisation",
                    type: "string",
                    width: "400px",
                    mandatoryMessage: "La localisation est requise",
                    readonly: false,
                },
                {
                    field: "Catégorie",
                    label: "Catégorie",
                    type: "select",
                    width: "130px",
                    mandatoryMessage: "La catégorie est requise",
                    options: "getCategoriesNameList", // This will reference the method to get options dynamically.
                },
                {
                    field: "Libellé Equipement",
                    label: "Libellé équipement",
                    type: "string",
                    width: "130px",
                    mandatoryMessage: "L'équipement doit être nommé",
                },
                {
                    field: "Caractéristiques techniques",
                    label: "Caractéristiques techniques",
                    type: "string",
                },
                {
                    field: "Valeur achat",
                    label: "Valeur achat",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Date mise en service",
                    label: "Date mise en service",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Référence constructeur",
                    label: "Référence constructeur",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Caractéristiques techniques",
                    label: "Caractéristiques techniques",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "N° de série",
                    label: "N° de série",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Marque",
                    label: "Marque",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Source de financement",
                    label: "Source de financement",
                    type: "number",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Référence document externe",
                    label: "Référence document externe",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "N° Comptable",
                    label: "N° Comptable",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Date fin de garantie",
                    label: "Date fin de garantie",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Durée de vie prévisionnelle",
                    label: "Durée de vie prévisionnelle",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Date remplacement prévisionnelle",
                    label: "Date remplacement prévisionnelle",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                },
                {
                    field: "Fournisseur",
                    label: "Fournisseur",
                    type: "string",
                    width: "70px",
                    textAlign: "center",
                }
            ]

        };
    },
    watch: {
        searchQuery: {
            handler: function(query) {
                if (query != null && query.length) {
                    let searchQuery = query.split(",");
                    searchQuery.forEach((query, index)=>{
                        searchQuery[index] = query.trimStart();
                    });
                    if(searchQuery.length > 1 ){
                        this.agFilters.libel_equipement.value = searchQuery;
                    }else{
                        this.agFilters.libel_equipement.value = searchQuery[0];
                    }
                    
                    this.agFilters.qrCode.value = searchQuery.join('","');
                    this.agFilters.num_serie.value = searchQuery.join('","');
                    this.agFilters.categorieTags.value = searchQuery.join('","');
                    this.agFilters.carac_techniques.value = searchQuery.join('","');
                } else {
                    this.agFilters.libel_equipement.value = null;
                    this.agFilters.qrCode.value = null;
                    this.agFilters.num_serie.value = null;
                    this.agFilters.categorieTags.value = null;
                    this.agFilters.carac_techniques.value = null;
                }
                this.FiltersMixins_saveSearchQuery(query);
            }
        },
        agFilters:{
            handler:function(newFilters){
                //this.$storage.set("equipements-saved-filters", newFilters);
                this.FiltersMixins_saveFilters(newFilters);
            },
            deep:true
        },
        equipementsToImportAndAwaitingValidation:{
            handler:function(nrow){
                this.equipementsToImportRequiredAttentionQuantity = this.equipementsToImportAndAwaitingValidation.filter((equipement)=>!equipement.isValidated).length;
            },
            deep:true
        }
    },
    computed: {
        ...mapGetters({
            equipements: 'EquipementsStore/getCollection',
            selectedEquipments: 'EquipementsStore/getSelectedItems',
            categories: 'CategoriesStore/getCollection',
            counters: 'EquipementsStore/getCounters',
            metadatas: 'EquipementsStore/getMetadatas'
        }),
        getCategoriesNameList: function(){
            return this.categories.map((categorie)=>categorie.libelleCatgorie);
        },
        pageFilters: function(){
            return ["field_service", "field_path", "field_num_serie", "field_batchNumber", "field_categorie", "field_categorieTags", "field_caracteristiques", "field_etiqueter","field_statutVerification","field_isHorsService"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agFilters[filterName] && this.agFilters[filterName].value!=null && (this.agFilters[filterName].value && this.agFilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getIconEquipements: function(){
            return this.$vgutils.isMobile()?"Icone-blanche-equipement_VG.png":"equipement.png";
        },
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? this.$root.vgPurple : 'white';
        },
        tabs: function() {
            return [{
                    label: this.$t("tab-equipement-actif"),
                    name: "equipement",
                    counter: this.counters.filtered==this.counters.all?this.counters.all:this.counters.filtered+" / "+this.counters.all,
                    filters: {
                        field_sortie: { attr: "se.dateSortie", colId: "se.dateSortie", value: 1, action: "is_null"},
                        field_statutEquipement: { attr: "e.statut", colId: "e.statut", value: null, action: "equals"},
                        field_typeSortie: { attr: "se.type", value: null, action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-equipement-sortis"),
                    name: "sortis",
                    counter: this.counters.sortis,
                    filters: {
                        field_sortie: { attr: "se.dateSortie", colId: "se.dateSortie", value: 1, action: "is_not_null"},
                        field_statutEquipement: { attr: "e.statut", colId: "e.statut", value: null, action: "equals"},
                        field_typeSortie: { attr: "se.type", value: null, action: "equals"}
                    }
                },
                {
                    label: this.$t("tab-equipement-sortis-temporaire"),
                    name: "sortis-temporaire",
                    counter: null,
                    filters: {
                        field_sortie: { attr: "se.dateSortie", colId: "se.dateSortie", value: 1, action: "is_null"},
                        field_statutEquipement: { attr: "e.statut", colId: "e.statut", value: "pret", action: "equals"},
                        field_typeSortie: { attr: "se.type", value: null, action: "equals"}
                    }
                }
            ]
        },
        tabsImportEquipementValidation: function(){
            return [
                {
                    label: "Equipements à importer",
                    name: "equipements-to-import",
                    counter: this.equipementsToImportAndAwaitingValidation.length
                },
                {
                    label: "Equipements qui requiert votre attention avant l'importation",
                    name: "equipements-to-validate",
                    counter: this.equipementsToImportAndAwaitingValidation.filter((equipement)=>!equipement.isValidated).length
                }
            ]
        },
        isEquipementsSortisFocused: function(){
            return this.agFilters.field_sortie.action=="is_not_null";
        },
        getEquipementsTableId: function(){
            if(this.isEquipementsSortisFocused) return "vg-equipements-sortis-table";
            else return "vg-equipements-table";
        },
        hasActiveGlobalFilters: function(){
            let filters = this.agFilters;
            for (let filterName in filters) {
                if (filterName.startsWith("global_") && filters[filterName].value !== null) {
                    return true;
                }
            }
            return false;
        },
        getSelectedEquipementsIds: function(){
            return this.selectedEquipments.map((eq)=>eq.id);
        },
        equipementsToImport:{
            get() {
                // populate this.equipementsToImportAndAwaitingValidation with property isValidated
                this.equipementsToImportAndAwaitingValidation = this.equipementsToImportAndAwaitingValidation.map((equipement)=>{
                    if(!equipement.hasOwnProperty("isValidated")) equipement.isValidated = true;
                    return equipement;
                });
                let equipementsToImportRequiredAttention = this.equipementsToImportAndAwaitingValidation.filter((equipement)=>!equipement.isValidated);
                // Getter: Return the items for the child component
                if(this.showOnlyequipementsToImportRequiredAttention){
                     return equipementsToImportRequiredAttention;
                }
                return this.equipementsToImportAndAwaitingValidation;
            },
            set(value) {
                // Setter: Update items based on the child's input event
                /*this.equipementsToImportAndAwaitingValidation.forEach(equipement => {
                    console.log("set equipements to import", equipement);
                    if( equipement.$id == value.$id ){
                        equipement =  value;
                    }
                });
                console.log("set equipements to import");
                console.log(value);*/
                return;
            }
        }

    },
    methods: {
        onTabImportEquipementValidationClicked: function(e){
            if(e.name == "equipements-to-validate"){
                this.showOnlyequipementsToImportRequiredAttention = true;
            }else{
                this.showOnlyequipementsToImportRequiredAttention = false;
            }
        },
        afterUpdateEquipements: function(){
            this.isUpdateEquipementsFormDisplayed = false;
            this.fetchEquipements();
        },
        fetchEquipements: function(){
            this.isLoading = true;
            this.equipementsMixins_getEquipements(this.metadatas[this.getEquipementsTableId]).then((datas)=>{
                this.isLoading = false;
            });
        },
        deleteGlobalFilters: function(){
            let filtersName = Object.keys(this.agFilters);
            let filters = {};
            let filter = {};
            filtersName.forEach((name)=>{
                filter = Object.assign({}, {}, this.agFilters[name]);
                if(name.startsWith("global_")) filter.value = null;
                filters[name] = Object.assign({}, {}, filter);
            });
            this.agFilters = filters;
            this.FiltersMixins_saveFilters(this.agFilters);
        },
        goToSortieTemporaireEqps: function(){
            this.$router.push({
                "name":"_mouvements_equipements_create_type",
                "query":{
                    equipementsIds:btoa(JSON.stringify(this.getSelectedEquipementsIds))
                },
                "params":{
                    type:"pret"
                }
            });
        },
        goToRetourEqps: function(){
            this.$router.push({
                "name":"_mouvements_equipements_create_type",
                "query":{
                    equipementsIds:btoa(JSON.stringify(this.getSelectedEquipementsIds))
                },
                "params":{
                    type:"retour"
                }
            });
        },
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.agFilters = Object.assign({}, this.agFilters, filters);
        },
        onExcludedCategorieTags: function(tags){
            if(tags && tags.length!=0){
                this.agFilters.field_excludeCategorieTags.value = tags.split("|");
                this.agFilters.field_excludeCategorieTags_null.value = 1;
            }else{
                this.agFilters.field_excludeCategorieTags.value = null;
                this.agFilters.field_excludeCategorieTags_null.value = null;
            }
        },
        handleExportEquipementIntegrationModel:function(){
            this.equipementsMixins_getExcelFileModeleIntegration();
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agFilters[filterName].value = null;
            });
            this.searchQuery = null;
            this.FiltersMixins_deleteStoredCurrentFilters();
        },
        onTabClicked: function(e) {
            this.agFilters = Object.assign({}, this.agFilters, e.filters);
            this.FiltersMixins_saveDefaultTab(e.name);
        },
        filterTableByQrCode: function(content) {
            // ne peut pas utiliser agfilters car le openParenthesis et le or ne donnent pas ce qu'il faut
            //
            let filterEquipementCode = new Metadatas();
            filterEquipementCode.setFilters({qrCode: { attr: "e.qrCode",colId: "e.qrCode", value: content, action:"equals"}})
            this.equipementsMixins_getEquipements(filterEquipementCode);
            this.showCodeScanner = false;
        },
        /**
         * @param object node
         */
        selectCategorieTag:function(node){
            if(node.hasOwnProperty("id")){ 
                this.agFilters.field_categorie.value = node.libelleCatgorie;
                this.agFilters.field_categorieTags.value = null;
            }else{
                this.agFilters.field_categorie.value = null;
                this.agFilters.field_categorieTags.value = node.location;
            }
        },
        /**
        * Will filter differently between Piece and other type_lieu.
        *
        * @param VgTree::Node
        */
        handleSelectNode: function (node) {
            this.agFilters.field_path = {
                attr: "l.path",
                colId: "l.path",
                value: node.path,
                action: "contains"
            };
            this.setSelectedLieuFromTreeLieuNode(node)
        },
        setSelectedLieuFromTreeLieuNode: function(node){
            console.log({node})
            this.selectedPiece = node;
        },
        scanQrCode: function() {
            this.showCodeScanner = true;
        },
        handleCreateRapportPdf:function(e){

        },
        handleActionExport:function(e){
            let metadatas = this.metadatas[this.getEquipementsTableId]; // Attention écrit en dur commun au tableau equipements

            switch (e.name) {
                case "askForXLS":
                    this.equipementsMixins_getExcelFile(metadatas,this.$t('liste-equipements'),"xlsx").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.equipementsMixins_getExcelFile(metadatas,this.$t('liste-equipements'),"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":this.getEquipementsTableId}
                    }));
                    break;
                default:
            }
        },
        handleImportEquipementModel:function(files){
            this.isLoading = true;
            let equipementExcelImporter = new EquipementExcelImporter(files[0]);
            equipementExcelImporter.getJson().then((json)=>{
                console.log(json);
                if(equipementExcelImporter.getMissingFields().length){
                    this.isLoading = false;
                    alert(this.$t("equipement-import-missing-fields",{fields:equipementExcelImporter.getMissingFields().join(", ")}));
                    return;
                }
                if (window.confirm(this.$t("importer-modele-integration-confirm",{nbEquipement:json.length}))) {
                    this.equipementsMixins_ImportModelEquipementsExcel(json).then((response)=>{
                        this.agFilters.libel_equipement.value = null;
                        window.location.reload();
                    }).catch((error)=>{
                        this.isLoading = false;
                        alert(error);
                    });
                }else{
                    this.isLoading = false;
                }
                this.isLoading = false;
            }).catch((error)=>{
                alert(error);
            });
            return;
        },
        validationEquipementsAImporter(evt){
            console.log(evt);
            return;
            this.openValidationExcelTableBeforeImport = false;
            let equipements = this.equipementsToImportAndAwaitingValidation;
            // remove property isValidated and $id foreach equipement
            equipements = equipements.map((equipement)=>{
                delete equipement.isValidated;
                delete equipement.$id;
                return equipement;
            });
            this.equipementsMixins_ImportModelEquipementsExcel(equipements).then((response)=>{
                this.agFilters.libel_equipement.value = null;
                //this.isLoading = false;
                window.location.reload();
            }).catch((error)=>{
                alert(error);
            });
        },
        openEquipementsRapport:function(){
            this.$router.push({ name: '_equipements_rapport' });
        },
        /**
         * handleClickLieu -
         *
         * @param  {object} lieu lieu venant de vg-tree-lieu
         */
        handleClickRecenserEquipement:function(lieu){
            //this.$router.push({ name: '_recensement_equipement_lieu', params: { idLieu: lieu.id } });
            //this.$router.push({ name: '_recensement_equipement' });
            this.$store.dispatch("EquipementsStore/setSelectedItem", null);
            if(this.$vgutils.isMobile()){
                this.$router.push({ name: '_recensement_equipement' });
            }else{
                this.displayEquipementForm = true;
            }

        },
        handleSaveDeplacements:function(){
            location.reload();
        },
        initRestrictionCategorieTags: function(){
            let restrictionCategorieTags = window.sessionStorage.getItem('exclude_categorie_tags');
            this.agFilters.field_excludeCategorieTags.value = restrictionCategorieTags?restrictionCategorieTags.split("|"):null;
            this.agFilters.field_excludeCategorieTags_null.value = restrictionCategorieTags?1:null;
        },
        onRemoveSelectedNode: function(){
            this.agFilters.field_categorie.value = null;
            this.agFilters.field_categorieTags.value = null;
        },
    },
    created:function(){
        this.initRestrictionCategorieTags();
        this.FiltersMixins_page_name = "equipements";
        let filters = this.FiltersMixins_getStoredFilters();
        this.defaultTab = this.FiltersMixins_getDefaultFocusedTab();
        //if(filters && filters.hasOwnProperty("field_path")) this.agFilters.field_path = filters.field_path;
        this.agFilters = Object.assign(this.agFilters, filters, this.defaultFilters);
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
        if(this.FiltersMixins_getDefaultFocusedTab()) this.defaultTab = this.FiltersMixins_getDefaultFocusedTab();
    },
    destroyed:function(){
        this.$store.dispatch("EquipementsStore/deleteSelectedItems");
    }
};
</script>
<style lang="scss" scoped>
// on personnalise le label comme on veut
.label-file {
    cursor: pointer;
}
.label-file:hover {
    color: #535353;
}

// et on masque le input
.input-file {
    display: none;
}
</style>
