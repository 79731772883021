var Auth = /** @class */ (function () {
    function Auth(config) {
        this.config = {
            apiKey: ''
        };
        this.apiKey = '';
        this.config = config;
        this.apiKey = config.apiKey;
    }
    Auth.prototype.setApiKey = function (apiKey) {
        this.apiKey = apiKey;
    };
    Auth.prototype.getApiKey = function () {
        return this.apiKey;
    };
    return Auth;
}());
export default Auth;
