var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Metadatas from "../Metadatas";
import HttpClient from "./HttpClient";
var Equipements = /** @class */ (function (_super) {
    __extends(Equipements, _super);
    function Equipements() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.endpoint = '/equipements';
        _this.endpointSingleton = '/equipement';
        _this.appID = '';
        _this.restrictionsite = '';
        return _this;
    }
    Equipements.prototype.getEquipementVerifications = function (equipement_id, metadatas) {
        if (metadatas === void 0) { metadatas = new Metadatas('{"directives":[],"filters":[]}'); }
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID,
                            metadatas: metadatas.get()
                        };
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpointSingleton, "/").concat(equipement_id, "/verifications"), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, { datas: response }];
                }
            });
        });
    };
    Equipements.prototype.getEquipement = function (idEquipement, _options) {
        if (_options === void 0) { _options = { skipVueXStorage: false }; }
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID
                        };
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpointSingleton, "/").concat(idEquipement), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        response[0] = this.calculDepreciation(response[0]);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.getRapportAssets = function (metadatas) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID,
                            metadatas: metadatas.get(),
                            sites: this.restrictionsite
                        };
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/valeurs-financieres"), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.equipements];
                }
            });
        });
    };
    Equipements.prototype.getRapportAssetsExcelFile = function (metadatas, fileExtension) {
        if (fileExtension === void 0) { fileExtension = "xlsx"; }
        return __awaiter(this, void 0, void 0, function () {
            var query, fileType, contentType, responseType, response, blob, url, link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metadatas.setDirectives([]);
                        query = {
                            metadatas: metadatas.get()
                        };
                        fileType = fileExtension !== "csv" ? "excel" : "csv";
                        contentType = fileExtension !== "csv" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv";
                        responseType = fileExtension !== "csv" ? "blob" : "text";
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/valeurs-financieres/export/").concat(fileType), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        blob = fileExtension === "csv" ? new Blob(["\uFEFF" + response], { type: contentType }) : new Blob([response], { type: contentType });
                        url = window.URL.createObjectURL(blob);
                        link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "rapport_assets_".concat(this.formatDate(new Date()), ".").concat(fileExtension));
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        return [2 /*return*/];
                }
            });
        });
    };
    Equipements.prototype.getEquipements = function (metadatas, _options) {
        if (_options === void 0) { _options = { stored: true }; }
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID,
                            metadatas: metadatas.get(),
                            sites: this.restrictionsite
                        };
                        return [4 /*yield*/, this.apiRequest(this.endpoint, 'GET', query)];
                    case 1:
                        response = _a.sent();
                        response.equipements.forEach(function (equipement, i) {
                            response.equipements[i] = _this.calculDepreciation(equipement);
                        });
                        return [2 /*return*/, { datas: response.equipements, metadatas: response.meta }];
                }
            });
        });
    };
    Equipements.prototype.getEquipementsTachesActivesSites = function (site, metadatas) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID,
                            site: site,
                            metadatas: metadatas.get()
                        };
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/taches/active/site"), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, { datas: response.equipements, metadatas: response.meta }];
                }
            });
        });
    };
    Equipements.prototype.getExcelFileModeleIntegration = function (filename) {
        if (filename === void 0) { filename = "VG_modèle_importation_equipements"; }
        return __awaiter(this, void 0, void 0, function () {
            var query, response, url, link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = {
                            userId: this.appID,
                            sites: this.restrictionsite || ''
                        };
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/integration/model"), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        url = window.URL.createObjectURL(new Blob([response]));
                        link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "".concat(filename, "_").concat(this.formatDate(new Date()), ".xlsx"));
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        return [2 /*return*/];
                }
            });
        });
    };
    Equipements.prototype.getExcelFile = function (metadatas, filename, fileExtension) {
        if (filename === void 0) { filename = null; }
        if (fileExtension === void 0) { fileExtension = "xlsx"; }
        return __awaiter(this, void 0, void 0, function () {
            var query, fileType, contentType, responseType, response, blob, url, link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metadatas.setDirectives([]);
                        query = {
                            userId: this.appID,
                            sites: this.restrictionsite || '',
                            metadatas: metadatas.get(),
                            isUserTypeAsDemandeur: 0
                        };
                        fileType = fileExtension !== "csv" ? "excel" : "csv";
                        contentType = fileExtension !== "csv" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv";
                        responseType = fileExtension !== "csv" ? "blob" : "text";
                        return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/export/").concat(fileType), 'GET', query)];
                    case 1:
                        response = _a.sent();
                        blob = fileExtension === "csv" ? new Blob(["\uFEFF" + response], { type: contentType }) : new Blob([response], { type: contentType });
                        url = window.URL.createObjectURL(blob);
                        link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', "".concat(filename, "_").concat(this.formatDate(new Date()), ".").concat(fileExtension));
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        return [2 /*return*/];
                }
            });
        });
    };
    Equipements.prototype.createEquipements = function (equipements) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        equipements.forEach(function (equipement) {
                            if (!equipement.marker && equipement.marker == null)
                                delete equipement.marker;
                            if (navigator.geolocation) {
                                try {
                                    navigator.geolocation.getCurrentPosition(function (position) {
                                        equipement.posY = position.coords.latitude;
                                        equipement.posX = position.coords.longitude;
                                    });
                                }
                                catch (err) {
                                    console.warn("Cannot get position xy");
                                }
                            }
                        });
                        return [4 /*yield*/, this.apiRequest(this.endpointSingleton, 'POST', equipements)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.importModelEquipementsExcel = function (equipements) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("".concat(this.endpoint, "/integration/model"), 'POST', equipements)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.sortirEquipement = function (equipement, callback) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("".concat(this.endpointSingleton, "/sortie"), 'POST', equipement)];
                    case 1:
                        _a.sent();
                        callback && callback();
                        return [2 /*return*/];
                }
            });
        });
    };
    Equipements.prototype.remplacerEquipement = function (sortie, maintenance) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("/maintenance/".concat(maintenance.id, "/equipement/remplacement"), 'POST', sortie)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.update = function (equipement, _options) {
        if (_options === void 0) { _options = { skipVueXStorage: false }; }
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("".concat(this.endpointSingleton, "/").concat(equipement.id, "?userId=").concat(this.appID), 'PUT', equipement)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.updateEquipements = function (equipements) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest(this.endpoint, 'PUT', equipements)];
                    case 1:
                        response = _a.sent();
                        response.forEach(function (equipement) {
                        });
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.delete = function (equipement) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("".concat(this.endpointSingleton, "/").concat(equipement.id), 'DELETE', equipement)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.createEquipementsGlobauxFamilleSite = function (famille, equipements) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiRequest("/sites/".concat(famille, "/equipements/globaux?userId=").concat(this.appID), 'POST', equipements)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Equipements.prototype.calculDepreciation = function (equipement) {
        equipement.depreciationAnnuelle = 0;
        equipement.depreciationMensuelle = 0;
        equipement.depreciationCumulee = 0;
        equipement.depreciationRestante = 0;
        equipement.dateFin = null;
        equipement.moisUtilisation = "-";
        if (!equipement.miseEnService) {
            return equipement;
        }
        var tauxDepreciationAnnuel = Number(equipement.tauxDepreciationAnnuel);
        var tauxDepreciationEnPourcent = Number.isNaN(tauxDepreciationAnnuel) ? 0 : tauxDepreciationAnnuel;
        var valeurAchat = Number(equipement.valeurAchat);
        valeurAchat = Number.isNaN(valeurAchat) || valeurAchat === 0 ? 0 : valeurAchat;
        equipement.valeurAchat = valeurAchat;
        var dateDebut = equipement.miseEnService ? new Date(equipement.miseEnService) : new Date(equipement.created_at);
        if (!equipement.miseEnService || equipement.miseEnService.length === 0)
            equipement.miseEnService = equipement.created_at;
        equipement.depreciationRestante = valeurAchat;
        equipement.dateFin = new Date(dateDebut);
        equipement.dateFin.setFullYear(equipement.dateFin.getFullYear() + (100 / tauxDepreciationEnPourcent));
        equipement.moisUtilisation = this.diffMonths(new Date(), dateDebut);
        equipement.depreciationAnnuelle = Number(tauxDepreciationEnPourcent) / 100 * valeurAchat;
        equipement.depreciationMensuelle = (Number(tauxDepreciationEnPourcent) / 100 * valeurAchat) / 12;
        if (equipement.moisUtilisation !== 0) {
            var decote = equipement.depreciationMensuelle * equipement.moisUtilisation;
            equipement.depreciationCumulee = decote;
            if (decote > valeurAchat) {
                equipement.depreciationCumulee = valeurAchat;
            }
            if (decote < valeurAchat) {
                var depreciationRestante = equipement.depreciationMensuelle * (this.diffMonths(equipement.dateFin, dateDebut) - equipement.moisUtilisation);
                equipement.depreciationRestante = depreciationRestante;
            }
        }
        return equipement;
    };
    Equipements.prototype.formatDate = function (date) {
        var day = String(date.getDate()).padStart(2, '0');
        var month = String(date.getMonth() + 1).padStart(2, '0');
        var year = date.getFullYear();
        return "".concat(day, "-").concat(month, "-").concat(year);
    };
    Equipements.prototype.diffMonths = function (date1, date2) {
        var years = date1.getFullYear() - date2.getFullYear();
        var months = date1.getMonth() - date2.getMonth();
        return years * 12 + months;
    };
    return Equipements;
}(HttpClient));
export default Equipements;
