var Logger = /** @class */ (function () {
    function Logger() {
    }
    Logger.logRequest = function (endpoint, method, data) {
        console.log("[Request] ".concat(method, " ").concat(endpoint), data);
    };
    Logger.logResponse = function (data) {
        console.log("[Response]", data);
    };
    Logger.logError = function (error) {
        console.error("[Error]", error);
    };
    return Logger;
}());
export default Logger;
