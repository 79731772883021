<template>
  <div>
    <vg-modal v-if="equipement"
        :width="wideWidth"
        v-show="!isModalConfirmDeleteOpened"
        @close="close" >
        <template #header>
            <span v-if="isAnUpdateForm && !isGlobal">{{$t("modal-title-update")}}</span>
            <span v-else-if="!isGlobal">{{$t("modal-title-create")}} <span v-if="selectedLieu" > {{$t('into')}} <b>{{selectedLieu.libel_lieu}}</b></span> </span>
            <span v-else>{{$t("modal-title-create-equipement-global")}}</span>
        </template>
        <template #body v-if="!equipement.idLieu_id">
            <div style="display:flex;justify-content:flex-start;align-items:center;gap:10px;margin-bottom:10px;">
                <vg-button
                    :type="'info'"
                    @click="showTreeLieu=!showTreeLieu">
                    {{showTreeLieu?$t("show-list-lieux"):$t("show-tree-lieux")}}
                </vg-button>
                <vg-button
    				type="grey"
    				@click="openPlansInteractifs=true;">
    				<i class="far fa-map"></i> <span>{{ $t("btn-plans-interactifs") }}</span>
    			</vg-button>
                <vg-button
                    type="grey"
                    @click="openTiroir=true;">
                    <i class="far fa-map"></i> <span>{{ $t("btn-plans-pdf") }}</span>
                </vg-button>
            </div>
            <vg-tree-lieux v-if="showTreeLieu"
                :readOnly="true"
                @select-piece="handleClickLieu">
            </vg-tree-lieux>
            <div v-else>
                <vg-text-filter @input="onInputSearchPiece" />
                <vg-filter-site v-model="agfiltersPieces.path.value"
                    style="margin: 10px 0;" />
                <vg-filter-service v-model="agfiltersPieces.service.value"
                    style="margin: 10px 0;" />
                <vg-lieux-table
                    :id="'vg-lieux-recensement-table'"
                    :filters="agfiltersPieces"
                    paginate
                    isDemandeIntervention
                    @select-piece="handleClickLieu" />
            </div>
        </template>

        <template #body v-else-if="equipement.idLieu_id && isGlobal" >
            <form id="equipement-global-form"
                ref="equipement-global-form"
                onSubmit="return false;">
                <vg-input :title="$t('categorie-generique')"
                    :is-required-value="true">
                    <template #actions>
                        <vg-button type="success-link"
                            @click="isNouvelleCategorieGenerique=true;showNouvelleCategorieForm=true;">
                            {{$t("nouvelle-categorie")}}
                        </vg-button>
                    </template>
                    <div style="display:flex;justify-content:space-between;align-items:center;">
                        <vg-categorie-selector v-model="equipement.idCategorie_id"
                            :isGenerique="true"
                            :isGe="false"
                            @input="onSelectCategorie">
                        </vg-categorie-selector>
                    </div>
                </vg-input>
                <vg-input
                    v-model="equipement.libel_equipement"
                    :title="$t('libel')"
                    :is-required-value="true">
                </vg-input>
                <input
                  v-show="false"
                  ref="equipement-global-submit"
                  type="submit" >
            </form>
        </template>

        <template #body v-else-if="equipement.idLieu_id && !isGlobal && !getCurrentCategorie">
            <!-- first step -->
            <vg-input
                v-model="equipement.idCategorie_id"
                :title="$t('categorie')"
                :is-required-value="true">
                <template #actions>
                    <vg-button type="success-link"
                        @click="isNouvelleCategorieGenerique=false;showNouvelleCategorieForm=true;">
                        {{$t("nouvelle-categorie")}}
                    </vg-button>
                </template>
                <vg-categorie-selector v-model="equipement.idCategorie_id"
                    showCreate
                    :isGe="false"
                    @input="onSelectCategorie" />
            </vg-input>
        </template>

        <template #body-left v-if="equipement.idLieu_id && !isGlobal && getCurrentCategorie">
            <div class="title-section">{{$t("caracteristique-equipement")}}</div>
            
            
            <vg-input v-if="!$vgutils.isMobile() && !isAnUpdateForm"
                :title="$t('quantite-a-creer')"
                isRequiredValue
                inline
                v-model="equipement.qty"
                inputType="number"
                :placeholder="$t('quantite-a-creer')" />
            <vg-input v-if="!$vgutils.isMobile() && !isAnUpdateForm && equipement.qty > 1"
                :title="$t('a-partir-du-numero')"
                isRequiredValue
                inline
                v-model="equipement.numeroDebut"
                inputType="number"
                :placeholder="$t('a-partir-du-numero')" />
            

            <vg-input :title="$t('libel-equipement')">
                <div class="equipement-form-inline-inputs">
                    <span v-show="!isAnUpdateForm" class="libelle-categorie-selectionne">{{getCurrentCategorie.libelleCatgorie}}</span>
                    <vg-input v-show="!isAnUpdateForm"
                        :disabled="equipement.qty > 1"
                        v-model="equipement.libel_equipement"
                        :placeholder="$t('complement')" />
                    <vg-input v-show="isAnUpdateForm"
                        v-model="equipement.libel_equipement"
                        :placeholder="$t('libel')" />
                </div>
            </vg-input>

            <vg-input v-if="equipement && equipement.categorie"
                :title="$t('categorie')"
                isRequiredValue="true">
                <vg-categorie-selector v-model="equipement.idCategorie_id"
                    :isGe="false"
                    @input="onSelectCategorie" />
            </vg-input>

            <vg-input v-model="equipement.marque"
                :title="$t('marque')" />

            
            <div style="display:flex;justify-content:space-between;align-items:center;gap:10px;">
                <vg-input v-model="equipement.refConstructeur"
                    :title="$t('reference-constructeur')" />
                <div v-if="$vgutils.isMobile()">
                    <vg-button
                        type="grey"
                        size="lg"
                        @click="isScannerRefConstructeurDisplayed=true">
                        <i class="fas fa-qrcode"></i>
                    </vg-button>
                </div>
            </div>

            <div style="display:flex;justify-content:space-between;align-items:center;gap:10px;">
                <vg-input
                    :disabled="equipement.qty > 1"
                    v-model="equipement.num_serie"
                    :title="$t('numero-serie')" />
                <div v-if="$vgutils.isMobile()">
                    <vg-button
                        type="grey"
                        size="lg"
                        @click="isScannerNumSerieDisplayed=true">
                        <i class="fas fa-qrcode"></i>
                    </vg-button>
                </div>
            </div>

            <vg-modal v-if="isScannerNumSerieDisplayed || isScannerRefConstructeurDisplayed"
                :title="isScannerNumSerieDisplayed?$t('scann-numero-serie'):$t('scann-reference-constructeur')"
                @close="isScannerNumSerieDisplayed=false;isScannerRefConstructeurDisplayed=false;">
                <template #body>
                    <zxing-scanner
                        @scanSuccess="handleScanCode"
                        :code="'barcode'"
                        key="scanner-num-serie" />
                </template>
                <template #footer>
                    <vg-button @click="isScannerNumSerieDisplayed = false;isScannerRefConstructeurDisplayed=false;">
                        {{$t("close")}}
                    </vg-button>
                </template>
            </vg-modal>
            

            <vg-input v-model="equipement.ReferenceDocumentExterne"
                :title="$t('reference-document-externe')" />

            <vg-input :title="$t('fournisseur')">
                <vg-tiers-selector v-model="equipement.fournisseur_id" />
            </vg-input>

            <vg-input
                v-model="equipement.carac_techniques"
                :title="$t('caracteristique')"
                inputType="textarea" />

            <vg-input v-if="isAnUpdateForm"
                :title="$t('etiquetter')" >
                <vg-equipement-etiquetage-selector v-model="equipement.etiquetter" />
            </vg-input>

            <vg-input v-model="equipement.numeroImmobilisation" :title="$t('numero-comptable')" />

            <vg-checkbox :label="$t('is-hors-service')" v-model="equipement.isHorsService" :defaultChecked="equipement.isHorsService"/>
        </template>

        <template #body-right v-if="equipement.idLieu_id && !isGlobal && getCurrentCategorie">
            <div class="title-section">{{$t("gestion-financiere")}}</div>

            <!--vg-input :title="$t('type')">
                <vg-select
                    v-model="equipement.type"
                    :placeholder="$t('type')"
                    :options="['Asset','Building']"
                    :clearable="false" />
            </vg-input-->
            
            <!-- date mise en service -->
            <vg-input v-if="!isUnknowDateMiseEnService"
                v-model="miseEnServiceRemake"
                :inputType="'date'"
                :title="$t('mise-en-service')"
                @input="onInputDateMiseEnService" />
            <vg-input :title="$t('mise-en-service')">
                <vg-checkbox :label="$t('date-mise-en-service-inconnue')"
                    :defaultChecked="isUnknowDateMiseEnService"
                    @checked="onSetDateMiseEnService(false)"
                    @unchecked="onSetDateMiseEnService(true)"/>
            </vg-input>
            <!-- duree vie prévisionnelle en années -->
            <vg-input v-if="(!isAnUpdateForm && !isUnknowDateMiseEnService)"
                :title="$t('duree-de-vie-previsionnelle')" >
                <div class="equipement-form-inline-inputs">
                    <vg-input v-model="equipement.dureeDeViePrevisionnelle"
                        :inputType="'number'"
                        style="width:30%"/>
                    <span>{{$t("ans")}}</span>
                </div>
            </vg-input>
            <!-- date fin de vie prévisionnelle : calculer à partir date mise en service + durée vie -->
            <vg-input v-if="!isUnknowDateMiseEnService"
                v-model="equipement.dateRemplacementPrevisionelle"
                :inputType="'date'"
                :title="$t('date-remplacement-previsionnelle')" />
            <!-- durée garantie en années : default=1 -->
            <vg-input v-if="!isUnknowDateMiseEnService"
                :title="$t('duree-garantie')" >
                <div class="equipement-form-inline-inputs">
                    <vg-input v-model="equipement.dureeGarantie"
                        :inputType="'number'"
                        @input="calculDateFinGarantie"
                        style="width:30%"/>
                    <span>{{$t("ans")}}</span>
                </div>
            </vg-input>
            <!-- date fin de garantie = date mise en service + durée garantie -->
            <vg-input v-if="!isUnknowDateMiseEnService"
                v-model="equipement.dateExpirationGarantie"
                :inputType="'date'"
                :title="$t('date-expiration-garantie')"  />

            <vg-input
                v-model="equipement.valeurAchat"
                :inputType="'number'"
                :title="$t('valeur-achat') +' '+$app.devise" />

            <vg-input
                v-model="equipement.tauxDepreciationAnnuel"
                :inputType="'number'"
                :title="$t('taux-depreciation-annuel')" />

            <vg-input
                v-model="equipement.sourceFinancement"
                :title="$t('source-financement')" />
        </template>

        <template #footer>
            <vg-button v-if="isAnUpdateForm && isUserIsAuthorize"
                :type="'danger'"
                @click="isModalConfirmDeleteOpened=true">
                {{$t("delete")}}
            </vg-button>
            <vg-button
                :type="'default'"
                @click="close()">
                {{$t("cancel")}}
            </vg-button>
            <vg-button
                :type="'success'"
                :disabled="isSaveDisabled || isLoading"
                @click="handleSave">
                {{$t("save")}}
            </vg-button>
        </template>
    </vg-modal>
    <dialog-secured-action
        v-if="isModalConfirmDeleteOpened"
        :valid-response="'1'"
        @save="onDeleteEquipement"
        @close="isModalConfirmDeleteOpened=false;">
        <template #header-title>
            {{ $t("confirm-delete-equipement") }} {{equipement.libel_equipement}} ?
        </template>
    </dialog-secured-action>

    <vg-categorie-form v-if="showNouvelleCategorieForm"
        :isGenerique="isNouvelleCategorieGenerique"
        @close="showNouvelleCategorieForm=false"
        @save="afterSaveCategorie" />

    <vg-etages-plans-interactifs-modal v-if="openPlansInteractifs"
        @close="openPlansInteractifs=false;" />

    <vg-files-plans v-if="openTiroir"
        @close="openTiroir = false" />
  </div>
</template>
<script>
/**
* @TODO create equipement !isGlobal !implement
*/
import equipementsMixins from "src/mixins/equipementsMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";


import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgModal from 'src/components/Vg/VgModal.vue';

import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";

import VgTiersSelector from "src/components/Vg/Selectors/VgTiersSelector.vue";
import VgEquipementEtiquetageSelector from "src/components/Vg/Selectors/VgEquipementEtiquetageSelector.vue";

import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import VgCategorieForm from "src/components/Vg/Forms/VgCategorieForm.vue";
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";

import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterService from "src/components/Vg/Filters/VgFilterService.vue";
import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
import VgLieuxTable from "src/components/Vg/Lieu/VgLieuxTable.vue";
import VgEtagesPlansInteractifsModal from "src/components/Vg/Lieu/VgEtagesPlansInteractifsModal.vue";
import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";

import { mapGetters } from 'vuex';
import ObjectUtilities from "src/utilities/ObjectUtilities.js";

export default {
    name: 'vg-equipement-form',
    components:{
        VgButton,
        VgInput,
        VgSelect,
        VgCheckbox,
        VgCollapse,
        VgModal,
        zxingScanner,
        VgTiersSelector,
        DialogSecuredAction,
        VgCategorieSelector,
        VgCategorieForm,
        VgEquipementEtiquetageSelector,
        VgTreeLieux,
        VgFilterSite,
        VgFilterService,
        VgTextFilter,
        VgLieuxTable,
        VgEtagesPlansInteractifsModal,
        VgFilesPlans
    },
    mixins: [ equipementsMixins, LieuMixins ],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modal-title-create": "Créer équipement",
            "into": "dans",
            "modal-title-create-equipement-global": "Créer équipement global",
            "modal-title-update": "Modifier l'équipement",
            "libel": "Libellé",
            "categorie": "Catégorie",
            "marque": "Marque",
            "caracteristique": "Caractéristiques techniques",
            "categorie-generique": "Catégorie",
            "reference-constructeur": "Référence constructeur",
            "fournisseur": "Fournisseur",
            "numero-serie": "Numéro série",
            "numero-comptable": "Numéro comptable",
            "delete": "Supprimer",
            "cancel": "Annuler",
            "save": "Enregistrer",
            "confirm-delete-equipement": "Confirmer la suppression de l'équipement",
            "nouvelle-categorie": "Ajouter catégorie",
            "mise-en-service": "Date mise en service",
            "date-mise-en-service-inconnue": "Date de mise en service inconnue",
            "gestion-financiere": "Gestion financière et calendaire",
            "taux-depreciation-annuel": "Taux dépréciation annuelle en %",
            "valeur-achat": "Valeur achat",
            "duree-amortissement": "Durée amortissement",
            "caracteristique-equipement": "Caractéristiques techniques",
            "source-financement": "Source financement",
            "quantite-a-creer": "Quantité à créer",
            "a-partir-du-numero": "commencer la numérotation à",
            "duree-de-vie-previsionnelle": "Durée de vie prévisionnelle",
            "date-expiration-garantie": "Date fin de garantie",
            "date-remplacement-previsionnelle": "Date fin de vie prévisionnelle",
            "duree-garantie": "Durée garantie",
            "reference-document-externe": "Référence document externe",
            "etiquetter": "Etiquette collée ?",
            "show-list-lieux": "Basculer vue liste",
            "show-tree-lieux": "Basculer vue arborescence",
            "btn-plans-interactifs": "Plans interactifs",
            "btn-plans-pdf": "Plans PDF",
            "libel-equipement": "Libellé équipement",
            "complement": "Complément libellé",
            "type": "Type d'équipement",
            "is-hors-service": "Hors service",
            "scann-numero-serie": "Scanner le numéro de série",
            "scann-reference-constructeur": "Scanner la référence constructeur",
            "close": "Fermer"
        },
        "en": {
            "modal-title-create": "Create equipment",
            "into": "into",
            "modal-title-create-equipement-global": "Create equipment",
            "modal-title-update": "Update equipment",
            "libel": "Naming",
            "categorie": "Category",
            "marque": "Brand",
            "caracteristique": "Technical characteristics",
            "categorie-generique": "Category",
            "reference-constructeur": "Manufacturer reference",
            "fournisseur": "Supplier",
            "numero-serie": "Serial number",
            "numero-comptable": "Accounting number",
            "delete": "Delete",
            "cancel": "Cancel",
            "save": "Save",
            "confirm-delete-equipement": "Confirm equipment removal",
            "nouvelle-categorie": "Add category",
            "mise-en-service": "Commissioning date",
            "date-mise-en-service-inconnue": "Unknown commissioning date",
            "gestion-financiere": "Financial and calendar management",
            "taux-depreciation-annuel": "Annual depreciation rate %",
            "valeur-achat": "Purchase value",
            "duree-amortissement": "Amortization period",
            "caracteristique-equipement": "Technical characteristics",
            "source-financement": "Funding source",
            "quantite-a-creer": "Quantity to create",
            "a-partir-du-numero": "start numbering at",
            "duree-de-vie-previsionnelle": "Expected lifespan",
            "date-expiration-garantie": "Warranty end date",
            "date-remplacement-previsionnelle": "Provisional replacement date",
            "duree-garantie": "Guaranteed duration",
            "reference-document-externe": "External document reference",
            "etiquetter": "Label code sticked ?",
            "show-list-lieux": "See list view",
            "show-tree-lieux": "See tree view",
            "btn-plans-interactifs": "Interactive level plans",
            "btn-plans-pdf": "PDF level plans",
            "libel-equipement": "Equipement name",
            "complement": "Complemetary name",
            "type": "Equipment type",
            "is-hors-service": "Out of service",
            "scann-numero-serie": "Scan serial number",
            "scann-reference-constructeur": "Scan manufacturer reference",
            "close": "Close"
        }
    }
},
    props: {
        /**
        * création d'un équipement global
        */
        isGlobal: {
            type: Boolean,
            default: false
        },
        /**
        * identifiant du lieu container de l'équipement
        */
        idLieu: {
            type: Number | String,
            default: null
        },
        /**
        *
        * Identifiant de l'équipement à modifier.
        * S'il est nourrie et que l'objet équipement n'est pas déjà
        * passé par le parent il fetch cet équipement.
        */
        id:{
            type:Number,
            default:function(){
                return null;
            }
        },
        reloadAfterSave: {
            type: Boolean,
            default: true
        },
        /**
         * permet de ne pas considérer l'équipement stocker dans EquipementsStore/getSelectedItem
         * par exemple pour page zoom lieu lorsque l'user veut créer des équipements EquipementsStore/getSelectedItem est déjà nourrit avec l'équipement GEP de la pièce
         **/
        skipVueXStorage: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            equipementCopy: null,
            equipement: {
                userId: this.$app.appID,
                idLieu_id: this.idLieu,
                idCategorie_id: null,
                qrCode: null,
                etiquetter:0,
                type:"Asset",
                qty:1,
                numeroDebut:1,
                miseEnService: null,
                dureeDeViePrevisionnelle: 10,
                dateRemplacementPrevisionelle: null,
                dureeGarantie: 1,
                dateExpirationGarantie: null,
                numeroImmobilisation: null,
                valeurAchat: null,
                tauxDepreciationAnnuel: null,
                sourceFinancement: null
            },
            miseEnServiceRemake: null,
            selectedLieu:null,
            isModalConfirmDeleteOpened: false,
            showNouvelleCategorieForm: false,
            isNouvelleCategorieGenerique: false,
            showTreeLieu: false,
            agfiltersPieces: {
				type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"},
				path: {attr: "l.path", value: null, action: "contains"},
				service: {attr: "l.service", value: null, action: "equals"},
				libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
				codeTrois: {attr: "l.codeTrois", value: null, action: "contains", logicalOperator: "OR"},
				codeDeux: {attr: "l.codeDeux", value: null, action: "contains", logicalOperator: "OR"},
				servicetext: {attr: "l.service", value: null, action: "contains", closeParenthesis: true, logicalOperator: "OR"}
			},
            openPlansInteractifs: false,
            openTiroir: false,
            isUnknowDateMiseEnService: false,
            isLoading: false,
            isScannerRefConstructeurDisplayed: false,
            isScannerNumSerieDisplayed: false
        };
    },
    watch: {
        selectedEquipement: {
            handler: function(equipement){
                console.log("selectedEquipement",equipement)
                this.equipement = Object.assign({}, this.equipement, equipement);
                if(!this.isAnUpdateForm) this.initDates();
                this.initEquipement();
            },
            deep: true
        }
    },
    created: function(){
        if(!this.skipVueXStorage) {
            //if(this.selectedEquipement.miseEnService) this.selectedEquipement.miseEnService = moment(this.selectedEquipement.miseEnService).format("YYYY-MM-DD");
            this.equipement = Object.assign({}, this.equipement, this.selectedEquipement);
            this.equipementCopy = ObjectUtilities.copy(this.equipement);
        }
        if(!this.isAnUpdateForm) this.initDates();
        else this.isUnknowDateMiseEnService = this.equipement.miseEnService && this.equipement.miseEnService!=null?false:true;
        this.initEquipement();
    },
    mounted: function(){
        if(this.id) this.fetchEquipement();
    },
    computed:{
        ...mapGetters({
            selectedEquipement: "EquipementsStore/getSelectedItem",
            lieux: "LieuxStore/getCollection",
            categories: "CategoriesStore/getCollection"
        }),
        isAnUpdateForm: function(){
            return this.equipement && this.equipement.hasOwnProperty('id') && this.equipement.id;
        },
        isUserIsAuthorize: function(){
            return this.$app.role=="ROLE_ADMIN";
        },
        getSite: function(){
            return this.lieux.find((lieu)=>lieu.id==this.idLieu);
        },
        getLibelSite: function(){
            let piece = this.lieux.find((lieu)=>lieu.id==this.idLieu);
            //console.log("PIECE", piece);
            return piece.path.split("/")[1];
        },
        getSiteInitiales: function(){
            let site = this.getLibelSite.split(" ");
            let initiales = site.map((mot)=>mot.charAt(0));
            return initiales.join("").toUpperCase();
        },
        /**
         * getCurrentCategorie.
         *
         * @return {object}  description
         */
        getCurrentCategorie:function(){
            if(!this.equipement.idCategorie_id) return null;
            else return this.categories.find((categorie)=>categorie.id==this.equipement.idCategorie_id);
        },
        isSaveDisabled: function(){
            return !this.equipement 
                || !this.equipement.idCategorie_id 
                || !this.equipement.idLieu_id 
                || !(this.equipement.qty && this.equipement.numeroDebut);
        },
        wideWidth:function(){
            if(this.equipement.idLieu_id && !this.isGlobal && this.getCurrentCategorie){
                return '70vw';
            }else{
                return '40vw';
            }
        }
    },
    methods: {
        /**
         * handleScanCode.
         *
         * @param  {string} scanResult
         */
        handleScanCode: function(scanResult){
            if(this.isScannerNumSerieDisplayed){
                this.equipement.num_serie = scanResult;
                this.isScannerNumSerieDisplayed = false;
            }else if(this.isScannerRefConstructeurDisplayed){
                this.equipement.refConstructeur = scanResult;
                this.isScannerRefConstructeurDisplayed = false;
            }
        },
        fetchEquipement: function(){
            this.equipementsMixins_getEquipement(this.id, {skipVueXStorage: this.skipVueXStorage}).then((equipements)=>{
                this.equipement = Object.assign({}, this.equipement, equipements[0]);
                this.equipementCopy = ObjectUtilities.copy(equipements[0]);
            });
        },
        onSetDateMiseEnService: function(isKnown){
            this.isUnknowDateMiseEnService = !isKnown;
            if(!isKnown){ 
                this.equipement.miseEnService = null;
                this.miseEnServiceRemake = null;
                this.equipement.dateRemplacementPrevisionelle = null;
                this.equipement.dateExpirationGarantie = null;
            }else this.initDates();
        },
        onInputSearchPiece: function(input){
            this.agfiltersPieces.libel_lieu.value = input;
			this.agfiltersPieces.codeTrois.value = input;
			this.agfiltersPieces.codeDeux.value = input;
			this.agfiltersPieces.servicetext.value = input;
        },
        onSelectCategorie: function(idCategorie){
            if(!this.isAnUpdateForm) this.setLibelEquipement();
            let categorie = this.categories.find((categorie)=>categorie.id==idCategorie);
            this.equipement.valeurAchat = categorie.prixDefault;
            this.equipement.tauxDepreciationAnnuel = categorie.tauxDepreciationAnnuelDefault;
        },
        setLibelEquipement: function(){
            let categorie = this.categories.find((categorie)=>categorie.id==this.equipement.idCategorie_id);
            if(this.isGlobal) this.equipement.libel_equipement = this.LieuMixins_getInitiales(this.getSite)+categorie.libelleCatgorie;
        },
        afterSaveCategorie: function(categorie){
            this.equipement.idCategorie_id = categorie.id;
            this.setLibelEquipement();
            this.showNouvelleCategorieForm = false;
        },
        /**
        * Save l'equipement.
        */
        handleSave: function(){
            this.isLoading = true;
            let form;
            delete this.equipement.dureeDeViePrevisionnelle;
            delete this.equipement.dureeGarantie;
            if(this.equipementCopy){
                delete this.equipementCopy.dureeDeViePrevisionnelle;
                delete this.equipementCopy.dureeGarantie;
            }

            if(this.isGlobal){
                form = this.$refs["equipement-global-form"];
                if(form.checkValidity()){
                    this.equipement.qrCode += "VLGE-"+moment().valueOf()+"-global";
                    this.equipementsMixins_createEquipements([this.equipement]).then((datas)=>{
                        this.$emit("save", datas[0]);
                    });
                }
            }else{
                form = this.$refs["equipement-form"];
                if(!this.isAnUpdateForm){ // création d'un équipement
                    let complementLibellerEquipement = this.equipement.libel_equipement ?
                     " " + this.equipement.libel_equipement :
                     ""
                     ;
                    this.equipement.type = "Asset"; // force Assets type
                    this.equipement.libel_equipement  = this.getCurrentCategorie.libelleCatgorie + complementLibellerEquipement;
                    this.equipement.miseEnService = this.miseEnServiceRemake?moment(this.miseEnServiceRemake).format("YYYY-MM-DD 00:00"): null;
                    this.equipementsMixins_createEquipements([this.equipement]).then((datas)=>{
                        if(this.reloadAfterSave){
                            location.reload();
                            this.close();
                        }else{
                            this.$emit("save", datas);
                        }
                    });
                }else{ // update d'un équipement
                    //this.equipement.type = "Asset"; // force Assets type
                    let updatedFields = ObjectUtilities.compareObjects(this.equipementCopy, this.equipement);
                    updatedFields["id"] = this.equipement["id"];
                    updatedFields["userId"] = this.equipement["userId"];
                    console.log({updatedFields});
                    this.equipementsMixins_update(updatedFields, {skipVueXStorage: this.skipVueXStorage}).then((data)=>{
                        this.$emit("save", data);
                    });
                    //this.$emit("save", this.equipement);
                }
            }
        },
        onInputDateMiseEnService: function(date){
            this.isUnknowDateMiseEnService = false;
            this.equipement.miseEnService = moment(date).format("YYYY-MM-DD 00:00");
            this.calculDateRemplacementPrevisionnelle();
            this.calculDateFinGarantie();
        },
        calculDateRemplacementPrevisionnelle: function(){
            console.log("CALCUL DATE REMPLACEMENT PREVISIONNELLE", this.equipement.miseEnService, this.equipement.dureeDeViePrevisionnelle);
            if(this.equipement.dureeDeViePrevisionnelle != 0 ){
                this.equipement.dateRemplacementPrevisionelle = moment(this.equipement.miseEnService).add(this.equipement.dureeDeViePrevisionnelle, "years").format("YYYY-MM-DD");
            }
        },
        calculDateFinGarantie: function(){
            console.log("CALCUL DATE FIN GARANTIE", this.equipement.miseEnService, this.equipement.dateExpirationGarantie);
            this.equipement.dateExpirationGarantie = moment(this.equipement.miseEnService).add(this.equipement.dureeGarantie, "years").format("YYYY-MM-DD");
        },
        initDates: function(){
            if(!this.equipement.miseEnService){ 
                this.equipement.miseEnService = moment().format("YYYY-MM-DD");
                this.miseEnServiceRemake = moment().format("YYYY-MM-DD");
            }else{ 
                this.miseEnServiceRemake = moment(this.equipement.miseEnService).format("YYYY-MM-DD");
            }
            this.calculDateRemplacementPrevisionnelle();
            this.calculDateFinGarantie();
        },
        initEquipement: function(){
            if(this.getCurrentCategorie){
                this.equipement.tauxDepreciationAnnuel = this.getCurrentCategorie.tauxDepreciationAnnuelDefault;
                this.equipement.sourceFinancement = this.getCurrentCategorie.sourceFinancementDefault;
            }
            if(this.equipement.miseEnService) this.miseEnServiceRemake = moment(this.equipement.miseEnService).format("YYYY-MM-DD");
        },
        close: function(){
            this.$emit('close');
        },
        /**
         * handleClickLieu -
         *
         * @param  {object} lieu lieu venant de vg-tree-lieu
         */
        handleClickLieu:function(lieu){
            this.selectedLieu = lieu;
            this.equipement.idLieu_id = lieu.id;
            this.equipement.etiquetter = 0;
        },
        onDeleteEquipement: function(){
            this.equipementsMixins_delete(this.equipement).then(()=>{
                this.$router.go(-1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.equipement-form-inline-inputs{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
    .libelle-categorie-selectionne{
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        background-color: #d8d9da;
        padding: 6px 10px;
        font-weight: bold;
        color: black;
        font-size:12px;
        white-space: nowrap;
    }
    span{
        margin: auto 0;
    }
}

.title-section{
    color: #35b1ea;
    font-size: 16px;
    margin-bottom: 10px;
}
</style>
<style lang="scss">
.equipement-form-inline-inputs{
    .vg-input-block{
        margin-bottom: 0 !important;
    }
}
</style>
<docs>

    ### vg-equipement-form
    Create|Update|Delete equipement

    #### basic usage

    ```html static
        <vg-equipement-form v-if="equipement || isEquipementFormDisplayed"
            v-model="equipement"
            @close="isEquipementFormDisplayed=false;equipement=null;"
            @created="isEquipementFormDisplayed=false;fetch()"
            @updated="isEquipementFormDisplayed=false;fetch()"
            @deleted="isEquipementFormDisplayed=false;fetch()"
        >
        </vg-equipement-form>
    ```
</docs>
