<template lang="html">
    <div class="vg-composant-selector" v-if="equipementGep">
        <vg-select 
            v-model="selectedComposant"
            :options="composantsFiltered"
            :attributeValue="attributeValue"
            :clearable="isClearable"
            :multiple="multiple"
            :placeholder="$t('placeholder')"
            @input="$emit('input', selectedComposant)">
            <template v-slot:selected-option="{option}">
                <div style="display:flex;justify-content:flex-start;align-items:center;gap:5px;">
                    <img :src="option.icon" style="height: 15px;width:15px;"/>
                    <span>{{option.libelComposant}}</span>
                </div>
            </template>
            <template v-slot="{option}">
                <div style="display:flex;justify-content:flex-start;align-items:center;gap:5px;">
                    <img :src="option.icon" style="height: 15px;width:15px;"/>
                    <span>{{option.libelComposant}}</span>
                </div>
            </template>
        </vg-select>
    </div>
    <div v-else>
        {{ $t("overlay-loading-text") }}
    </div>

</template>

<script>
import ComposantMobileCellRender from "src/components/Vg/TagGrid/ComposantMobileCellRender.vue";
import ComposantMixins from "src/mixins/ComposantMixins.js";
import EquipementsMixins from "src/mixins/equipementsMixins.js";
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";

export default {
    name: 'vg-composant-selector',
    components: {
        ComposantMobileCellRender,
        TagGrid,
        VgInput,
        VgSelect,
        VgCollapse
    },
    props:{
        idLieu:{
            type: Number,
            default:function(){
                return null;
            }
        },
        /**
        * @model
        */
        value: {
            type: String | Object,
            default: null
        },
        /**
        * attribut de l'objet contact à retourner comme valeur
        * default id
        * si attributeValue=null retourne l'objet sélectionné
        */
        attributeValue: {
            type: String,
            default: "id"
        },
        /**
        * selecteur clearable
        */
        isClearable: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
            default: function(){
                return {};
            },
        },
        multiple:{
            type: Boolean,
            default: false
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "overlay-loading-text": "Chargement des composants...",
            "overlay-no-rows-text": "Aucun composant correspondant au filtrage",
            "search": "Recherche...",
            "placeholder": "Sélectionner un composant"
        },
        "en": {
            "overlay-loading-text": "Loading components...",
            "overlay-no-rows-text": "No component matching filtering",
            "search": "Searching...",
            "placeholder": "Select a component"
        }
    }
},
    mixins: [ ComposantMixins, EquipementsMixins ],

    data: function(){
        return {
            showLoadingOverlay: false,
            metadatas: new Metadatas(),
            equipementGep: null,
            agfilters:{
                libelComposant: { attr:"c.libelComposant", colId: "libelComposant", value: null, action:"contains" },
                categorie: { attr:"cc.categorie", colId: "categorie_id", value: null, action:"equals" }
            },
            selectedComposant: this.value
        }
    },
    created:function(){
        this.fetchEquipement();
    },
    methods:{
        fetchEquipement: function(){
            let metadatasGep = new Metadatas();
            metadatasGep.setFilters({
                libelComposant: { attr:"e.isGroupEqp", colId: "e.isGroupEqp", value: "1", action:"equals" },
                lieu: { attr:"e.idLieu_id", colId: "lieu", value: this.idLieu, action:"equals" }
            });
            this.equipementsMixins_getEquipements(metadatasGep).then((equipements)=>{
                //console.log("equipementsMixins_getEquipements",equipements);
                this.equipementGep = equipements.datas[0];
                this.$emit("feed-equipement-gep", this.equipementGep);
                this.agfilters.categorie.value = equipements.datas[0].idCategorie;
                this.fetch();
            });
        },
        fetch: function(){
            this.showLoadingOverlay = true;
            // récupérer le GEP pour avoir son idCategorie
            // puis executer getComposant en passant comme filtre lidCategorie
            this.ComposantMixins_get(this.metadatas).then((composants)=>{
                this.showLoadingOverlay = false;
            });

        }
    },
    computed:{
        ...mapGetters({
            composants: 'ComposantsStore/getCollection',
        }),
        composantsFiltered: function(){
            // composant_categories
            let idCategorie = this.equipementGep.idCategorie;
            return this.composants.filter((composant)=>{
                return composant.composant_categories.some((composant_categorie)=>{
                    return composant_categorie.categorie.id == idCategorie;
                });
            });
        },
        /**
		 * @return array
		 */
		composantsGroupByType: function(){
			// @TODO refactorer
			var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
			};
			if(this.composants) return groupBy(this.composantsFiltered, "typeComposant");
            else return [];
		},
    }
}
</script>

<style lang="scss" >
.vg-composant-selector-cellrender{
    display: flex;
    align-items: center;
}
.vg-composant-selector{
    height: 100%;

    .tableau{
        height: 90%;
    }
}
</style>
<docs>

    ### vg-composant-selector


    ```js
          import VgComposantSelect from "src/components/Vg/Selectors/VgComposantSelector.vue";
    ```

    #### basic usage

    ```html static
          <vg-composant-selector  @select-row="selectComposant"></vg-input>
    ```
    #### Emit : select-row

    Retourne la valeur selectionner, clique sur la row

    ### mixins

    ComposantMixins_getComposants

</docs>
