<template>
    <vg-app-layout
        :title="$vgutils.isMobile()?$t('title-mobile'):$t('title')"
        :icon="'parametre.png'"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :isLoadingState="isLoadingState"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters">
        <template #create-button>
            <vg-button v-if="can('BONS.CREATE_BON_DE_SORTIE')"
                type="success"
                @click="showModalCreateBonDeSortie=true;">
                {{$t("button-new-bon-de-sortie")}}
            </vg-button>
            <vg-button v-if="can('BONS.CREATE_BON_DENTREE')"
                type="success"
                @click="showModalCreateBonDentree=true;">
                {{$t("button-new-bon-dentree")}}
            </vg-button>
        </template>
        <template #panel-menu-more>
            <vg-button v-if="can('BONS.CREATE_BON_DE_SORTIE')"
                type="success"
                @click="showModalCreateBonDeSortie=true;">
                {{$t("button-new-bon-de-sortie")}}
            </vg-button>
            <vg-button v-if="can('BONS.CREATE_BON_DENTREE')"
                type="success"
                @click="showModalCreateBonDentree=true;">
                {{$t("button-new-bon-dentree")}}
            </vg-button>
        </template>

        <template #search>
            <vg-text-filter
                v-model="searchQuery" />
        </template>

        <template #primary>
            <vg-tabs v-if="!$vgutils.isMobile()" v-model="focusedTab"
                :tabs="tabs"
                :color="'jaune'"
                @tabClicked="onChangeTabSigned" />
            <vg-tabs v-else v-model="focusedTabSignes"
                :tabs="tabsSignes"
                :color="'jaune'"
                @tabClicked="onChangeTabSigned" />
            <vg-bons-dentree-table v-show="tabIsBonsDentree"
                :filters="bonsDentreeFilters"
                paginate
                @row-dbl-click="onRowDblClick"/>
            <vg-bons-de-sortie-table v-show="tabIsBonsDeSortie"
                :filters="bonsDeSortieFilters"
                paginate />
        </template>
        <template #secondary-filters v-if="tabIsBonsDeSortie">
            <!--vg-filter-date-intervalle v-model="bonsDeSortieFilters.dateCreation.value"
                :title="$t('filter-date-intervalle')"
                :from="getFromDateIntervalle"
                :to="getToDateIntervalle"
                @change="onChangeFilterDateIntervalle"/-->
            <vg-filter-service v-model="bonsDeSortieFilters.field_serviceDemandeur.value" 
                showAll/>
            <vg-filter-site v-model="bonsDeSortieFilters.field_siteId.value"
                :attributeValue="'id'" />
        </template>
        <template #secondary-filters v-else-if="tabIsBonsDentree">
            <!--vg-filter-date-intervalle v-model="bonsDentreeFilters.dateCreation.value"
                :title="$t('filter-date-intervalle')"
                :from="getFromDateIntervalle"
                :to="getToDateIntervalle"
                @change="onChangeFilterDateIntervalle" /-->
            <vg-filter-tiers v-model="bonsDentreeFilters.fournisseur_id.value" />
        </template>
        <template #panel-filters-attributs v-if="tabIsBonsDeSortie">
            <vg-filter-date-intervalle
                :title="$t('filter-date-intervalle')"
                opens="right"
                :from="getFromDateIntervalle"
                :to="getToDateIntervalle"
                @change="onChangeFilterDateIntervalle"/>
            <vg-filter-service v-model="bonsDeSortieFilters.field_serviceDemandeur.value" 
                showAll/>
            <vg-filter-site v-model="bonsDeSortieFilters.field_siteId.value"
                :attributeValue="'id'" />
        </template>
        <template #panel-filters-attributs v-else-if="tabIsBonsDentree">
            <!--vg-filter-date-intervalle v-model="bonsDentreeFilters.dateCreation.value"
                :title="$t('filter-date-intervalle')"
                :from="getFromDateIntervalle"
                :to="getToDateIntervalle"
                @change="onChangeFilterDateIntervalle" /-->
            <vg-filter-tiers v-model="bonsDentreeFilters.fournisseur_id.value" />
        </template>

        <vg-bon-de-sortie-form v-if="showModalCreateBonDeSortie"
            @close="showModalCreateBonDeSortie=false;"
            @save="showModalCreateBonDeSortie=false;isLoadingState=false;"
            @loading="loadingState" />

        <vg-bon-dentree-form v-if="showModalCreateBonDentree"
            @close="showModalCreateBonDentree=false;"
            @save="showModalCreateBonDentree=false;" />

        <vg-bon-dentree-modal v-if="showBonEntreeModal"
            @close="showBonEntreeModal=false;"/>

    </vg-app-layout>
</template>

<script>

import VgInput from 'src/components/Vg/VgInput.vue';
import VgButton from "src/components/Vg/VgButton.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';

import VgBonsDentreeTable from 'src/components/Vg/BonsDentree/VgBonsDentreeTable.vue';
import VgBonsDeSortieTable from 'src/components/Vg/BonsDeSortie/VgBonsDeSortieTable.vue';
import VgBonDeSortieForm from 'src/components/Vg/Forms/VgBonDeSortieForm.vue';
import VgBonDentreeForm from 'src/components/Vg/Forms/VgBonDentreeForm.vue';
import VgBonDentreeModal from 'src/components/Vg/BonsDentree/VgBonDentreeModal.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

import VgFilterDateIntervalle from 'src/components/Vg/Filters/VgFilterDateIntervalle.vue';
import VgFilterService from 'src/components/Vg/Filters/VgFilterService.vue';
import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
import VgFilterTiers from 'src/components/Vg/Filters/VgFilterTiers.vue';

import StocksMixins from 'src/mixins/StocksMixins.js';
import FiltersMixins from 'src/mixins/FiltersMixins';

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";
export default {
	name: "bons",
	components:{
		VgInput,
		VgTabs,
        VgButton,
        VgTextFilter,
        VgBonsDentreeTable,
        VgBonsDeSortieTable,
        VgBonDeSortieForm,
        VgBonDentreeForm,
        VgBonDentreeModal,
        VgFilterDateIntervalle,
        VgFilterService,
        VgFilterSite,
        VgFilterTiers
	},
	mixins:[ StocksMixins, FiltersMixins ],
	data: function () {
		return {
			showModalCreateBonDeSortie: false,
			showModalCreateBonDentree: false,
            bonsDeSortieFilters: {
                numero: {attr: "bs.numero", value: null, action: "contains", openParenthesis:true},
                nomDemandeur: {attr: "fdc.nomDemandeur", value: null, action:"contains", logicalOperator: "OR"},
                description: {attr: "bs.description", value: null, action: "contains", logicalOperator:"OR", closeParenthesis: true},
                field_dateCreation: {attr: "bs.dateCreation", value: null, action: "between"},
                field_serviceDemandeur: {attr: "fdc.serviceDemandeur", value: null, action: "equals"},
                field_siteId: {attr: "s.id", value: null, action: "equals"},
                tab_signes: {attr: "bs.signataire", value: null, action: "is_not_null"},
                tab_non_signes: {attr: "bs.signataire", value: null, action: "is_null"}
            },
            bonsDentreeFilters: {
                dateCreation: {attr: "be.dateCreation", value: null, action: "between"},
                fournisseur_id: {attr: "be.fournisseur_id", value: null, action: "equals"},
                numero: {attr: "bs.numero", value: null, action: "contains", openParenthesis:true},
                description: {attr: "bs.description", value: null, action: "contains", logicalOperator:"OR", closeParenthesis: true}
            },
            focusedTab: "bonsdesortie",
            focusedTabSignes: "non-signes",
            searchQuery: "",
            isLoadingState: false,
            showBonEntreeModal: false
		};
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Bons de sortie / Bons d'entrée",
            "title-mobile": "Bons de sortie",
            "button-new-bon-de-sortie": "Créer bon de sortie",
            "button-new-bon-dentree": "Créer bon d'entrée",
            "tab-bons-dentree": "Bons d'entrée",
            "tab-bons-de-sortie": "Bons de sortie",
            "tab-bons-de-sortie-signes": "Bons de sortie signés",
            "tab-bons-de-sortie-non-signes": "Bons de sortie non signés",
            "filter-date-intervalle": "Date création",
            "quantite-en-stock": "Quantité en stock",
            "entre": "entre",
            "et": "et",
            "tab-signes": "Signés",
            "tab-non-signes": "Non signés"
        },
        "en": {
            "title": "Exit vouchers / Entry vouchers",
            "title-mobile": "Exit vouchers",
            "button-new-bon-de-sortie": "Create exit voucher",
            "button-new-bon-dentree": "Create entrance voucher",
            "tab-bons-dentree": "Entrance vouchers",
            "tab-bons-de-sortie": "Exit vouchers",
            "tab-bons-de-sortie-signes": "Signed exit vouchers",
            "tab-bons-de-sortie-non-signes": "Unsigned exit vouchers",
            "filter-date-intervalle": "Creation date",
            "quantite-en-stock": "Quantity in stock",
            "entre": "between",
            "et": "and",
            "tab-signes": "Signed",
            "tab-non-signes": "Unsigned"
        }
    }
},
	watch: {
        searchQuery: {
            handler: function(newquery){
                if(newquery == null){
                    this.bonsDeSortieFilters.numero.value = null;
                    this.bonsDeSortieFilters.description.value = null;
                    this.bonsDeSortieFilters.nomDemandeur.value = null;
                    this.bonsDentreeFilters.numero.value = null;
                    this.bonsDentreeFilters.description.value = null;
                }else{
                    this.bonsDeSortieFilters.numero.value = newquery;
                    this.bonsDeSortieFilters.description.value = newquery;
                    this.bonsDeSortieFilters.nomDemandeur.value = newquery;
                    this.bonsDentreeFilters.numero.value = newquery;
                    this.bonsDentreeFilters.description.value = newquery;
                }
                
                this.FiltersMixins_saveSearchQuery(newquery);
            }
        },
        bonsDeSortieFilters:{
            handler: function(filters){
                this.FiltersMixins_saveFilters(filters);
            },
            deep:true
        }
	},
	created: function () {
        this.FiltersMixins_page_name = "bonsSortiesEntrees";
        this.fetchDepots();
        if(this.$vgutils.isMobile()) this.bonsDeSortieFilters.tab_non_signes.value = 1;
	},
	mounted: function () {

	},
	methods:{
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.bonsDeSortieFilters[filterName].value = null;
            });
            this.searchQuery = null;
            this.FiltersMixins_deleteStoredCurrentFilters();
        },
        onChangeFilterDateIntervalle: function(dateIntervalle){
            console.log("CHANGE FILTER DATE INTERVALLE", dateIntervalle);
            if(dateIntervalle) this.bonsDeSortieFilters.field_dateCreation.value = [dateIntervalle.startDate, dateIntervalle.endDate];
            else this.bonsDeSortieFilters.field_dateCreation.value = null;
        },
        onChangeTabSigned: function(tab){
            this.bonsDeSortieFilters = Object.assign({}, this.bonsDeSortieFilters, tab.filters);
        },
        onRowDblClick: function(row){
            this.$store.dispatch("BonsDentreeStore/setSelectedItem", row.data);
            this.showBonEntreeModal = true;
        },
        loadingState: function(){
            this.isLoadingState = true;
        },
        fetchDepots: function(){
            return new Promise((resolve, reject)=>{
                let metadatasDepots = new Metadatas();
                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{

                    this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
                    let filters = this.FiltersMixins_getStoredFilters();
                    //if(filters && filters.hasOwnProperty("field_path")) this.agFilters.field_path = filters.field_path;
                    this.bonsDeSortieFilters = Object.assign(this.bonsDeSortieFilters, filters);
                    resolve(datas);
                });
            });
        },
        handleActionExport:function(e){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.tabIsBonsDentree?this.bonsDentreeFilters:this.bonsDeSortieFilters);
            switch (e.name) {
                case "askForXLS":
                    this.ConsommablesMixins_getFile(metadatas,"excel").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.ConsommablesMixins_getFile(metadatas,"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":this.tabIsBonsDentree? "bons-dentree-tableau": "bons-de-sortie-tableau"}
                    }));
                    break;
                default:
                    break;
            }
        },
	},
	computed:{
        ...mapGetters({
            countersBonsDeSortie: "BonsDeSortieStore/getCounters",
            countersBonsDentree: "BonsDentreeStore/getCounters",
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant"
        }),
        pageFilters: function(){
            return ["field_dateCreation", "field_serviceDemandeur", "field_siteId"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.bonsDeSortieFilters[filterName] && this.bonsDeSortieFilters[filterName].value!=null && (this.bonsDeSortieFilters[filterName].value && this.bonsDeSortieFilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
		tabs: function(){
            return [
                {label: this.$t("tab-bons-de-sortie"), name:"bonsdesortie", 
                    counter: this.countersBonsDeSortie && this.countersBonsDeSortie.all?this.countersBonsDeSortie.filtered+" / "+this.countersBonsDeSortie.all:"-",
                    filters: {
                        tab_signes: {attr: "bs.signataire", value: null, action: "is_not_null"},
                        tab_non_signes: {attr: "bs.signataire", value: null, action: "is_null"}
                    }
                },
                {label: this.$t("tab-bons-de-sortie-signes"), name:"bonsdesortiesignes", 
                    counter: this.countersBonsDeSortie && this.countersBonsDeSortie.signes?this.countersBonsDeSortie.signes:"-",
                    filters: {
                        tab_signes: {attr: "bs.signataire", value: 1, action: "is_not_null"},
                        tab_non_signes: {attr: "bs.signataire", value: null, action: "is_null"}
                    }
                },
                {label: this.$t("tab-bons-de-sortie-non-signes"), name:"bonsdesortienonsignes", 
                    counter: this.countersBonsDeSortie && this.countersBonsDeSortie.nonSignes?this.countersBonsDeSortie.nonSignes:"-",
                    filters: {
                        tab_signes: {attr: "bs.signataire", value: null, action: "is_not_null"},
                        tab_non_signes: {attr: "bs.signataire", value: 1, action: "is_null"}
                    }
                },
                {label: this.$t("tab-bons-dentree"), name:"bonsdentree", 
                    counter: this.countersBonsDentree && this.countersBonsDentree.all?this.countersBonsDentree.filtered+" / "+this.countersBonsDentree.all:"-"
                }
            ];
		},
        tabsSignes: function(){
            return [
                {label: this.$t("tab-non-signes"), name:"non-signes", value: "non-signes", filters: {
                    tab_signes: {attr: "bs.signataire", value: null, action: "is_not_null"},
                    tab_non_signes: {attr: "bs.signataire", value: 1, action: "is_null"}
                }},
                {label: this.$t("tab-signes"), name:"signes", value: "signes", filters: {
                    tab_signes: {attr: "bs.signataire", value: 1, action: "is_not_null"},
                    tab_non_signes: {attr: "bs.signataire", value: null, action: "is_null"}
                }}
            ];
        },
        tabIsBonsDentree: function(){
            return this.focusedTab=="bonsdentree";
        },
        tabIsBonsDeSortie: function(){
            return this.focusedTab=="bonsdesortie" || this.focusedTab=="bonsdesortiesignes" || this.focusedTab=="bonsdesortienonsignes";
        },
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
        },
        getDepotId: function(){
            if(this.focusedTab=='sortisnonconsommes' && this.depotFlottant) return this.depotFlottant.id;
            else if(this.depotDefault) return this.depotDefault.id;
            else return null;
        },
        getFromDateIntervalle: function(){
            return this.bonsDeSortieFilters.field_dateCreation.value?this.bonsDeSortieFilters.field_dateCreation.value[0]:null;
        },
        getToDateIntervalle: function(){
            return this.bonsDeSortieFilters.field_dateCreation.value?this.bonsDeSortieFilters.field_dateCreation.value[1]:null;
        },
    }
}
</script>

<style lang="scss">

</style>