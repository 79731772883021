<template>
	<div>
		<vg-modal
			@close="$emit('close')">
			<template #header>
				{{$t("modal-validate-intervention-title")}} &nbsp;
				<b>
					<vg-intervention-numero-viewer :intervention="value">
					</vg-intervention-numero-viewer>
				</b>
			</template>
			<template v-slot:body>
				<div v-if="!$vgutils.isMobile()" style="display:flex;justify-content:flex-start;">
					<vg-input :title="$t('date-previsionnelle')">
						{{ $t("du") }} <vg-datetime-viewer v-model="intervention.datePrevisionnelleDebut"/> {{ $t("au") }} <vg-datetime-viewer v-model="intervention.datePrevisionnelleFin" />
					</vg-input>
				</div>
				<hr v-if="!$vgutils.isMobile()">
				<!-- date début effective -->
				<vg-input
					v-model="intervention.dateEffectiveDebut"
					:title="$t('date-effective-debut')"
					:is-required-value="true"
					:inputType="'datetime-local'"
					:pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'"
					@input="onChangeDateEffectiveDebut">

				</vg-input>
				<!-- date fin effective -->
				<vg-input
					v-model="intervention.dateEffectiveFin"
					:title="$t('date-effective-fin')"
					:is-required-value="true"
					:inputType="'datetime-local'"
					:pattern="'[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}'">

				</vg-input>
				<!-- commentaire -->
				<vg-input
					v-model="intervention.commentaire"
					:title="$t('commentaire')"
					:inputType="'textarea'">

				</vg-input>
				<!-- documents -->
				<vg-input
					:title="$t('documents')" v-if="documentsInterventionTags">
					<!-- uploader documents -->
					<vg-files-uploader
						key="files-uploader-intervention-validation"
						:isUploadOnFileChange="false"
						:tagsList="documentsInterventionTags"
						@file-change="onFileChange" />
					<!--vg-files-viewer-list-section
						:files="documents" /-->
				</vg-input>
				<vg-input
					:title="$t('reserves')">
					<div v-if="reserves && reserves.length!=0" class="reserves-view">
						<div v-for="reserve,index in reserves" :key="index" class="reserve-equipement-view" >
							<vg-equipement-viewer
								:equipement="reserve.materiel" />
							<span style="margin-left:10px;">{{reserve.commentaireClient}}</span>
							<vg-button
								:type="'default-info'"
								:size="'sm'"
								@click="removeReserve(reserve,index)">
								<i class="fas fa-times"></i>
							</vg-button>
						</div>
					</div>
					<span v-else style="color:#3999ff;font-size:11px;">{{ $t("aucune-reserve") }}</span>
					<br>
					<br>
					<div class="reserve-equipement-add">
						<vg-input :title="$t('nouvelle-reserve')">
							<vg-equipement-selector v-model="reserve.materiel"
								:attributeValue="null"
								:agFilters="equipementsFilters"
								style="width:100%;margin-right:5px;margin-bottom:10px;" />
							<vg-input v-model="reserve.commentaireClient"
								:placeholder="$t('description')"
								style="width:100%;margin-right:5px;" />
							<vg-button v-if="!isAddReserveDisabled"
								:type="'info'"
								@click="addReserve">
								{{$t("add-reserve")}}
							</vg-button>
						</vg-input>
					</div>
				</vg-input>
			</template>
			<template v-slot:footer>
				<vg-files-uploader v-if="$vgutils.isMobile()"
					key="files-uploader-intervention-validation-mobile"
					:isOnlyPhoto="true"
					style="width:100px;"
					class="footer-intervention"
					@upload-done="onUploadPhotoDone" />
				<vg-button
				@click="$emit('close')">
				{{$t("cancel")}}
				</vg-button>
				<vg-button
					:type="'success'"
					:disabled="isLoading || !intervention.dateEffectiveDebut || !intervention.dateEffectiveFin"
					@click="onSaveValidateIntervention">
				{{$t("save")}}
				</vg-button>
			</template>
		</vg-modal>
		<vg-intervention-equipements-modal v-if="showEquipementsListModal"
			v-model="value"
			@close="$emit('save');"
			@save="$emit('save');" />
	</div>
</template>
<script>
	import InterventionsMixins from "src/mixins/InterventionsMixins.js";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import TagsMixins from "src/mixins/TagsMixins.js";

    import VgInterventionEquipementsModal from "src/components/Vg/Interventions/VgInterventionEquipementsModal.vue";
    import VgInterventionNumeroViewer from "src/components/Vg/Interventions/VgInterventionNumeroViewer.vue";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
    import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";


    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";

    import VgEquipementSelector from "src/components/Vg/Selectors/VgEquipementSelector.vue";
    import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";

    import Metadatas from "src/services/Metadatas.js";

    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-intervention-validation',
    	props: {
			/**
			* @model
			*/
			value: {
				type: Object
			}
        },
		mixins: [
			InterventionsMixins,
			MaintenanceMixins,
			TagsMixins
		],
		components: {

			VgInput,
			VgButton,
			VgInterventionEquipementsModal,
			VgInterventionNumeroViewer,
			VgFilesUploader,
			VgFilesViewerListSection,
			VgEquipementSelector,
			VgEquipementViewer,
			VgDatetimeViewer
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "date-previsionnelle": "Date prévisionnelle",
            "heure-debut": "Heure début",
            "heure-fin": "Heure fin",
            "date-effective-debut": "Date effective début",
            "date-effective-fin": "Date effective fin",
            "commentaire": "Commentaire",
            "documents": "Documents",
            "reserves": "Réserves",
            "modal-validate-intervention-title": "Valider l'intervention",
            "save": "Enregistrer",
            "cancel": "Annuler",
            "description": "Description",
            "add-reserve": "Ajouter réserve",
			"du": "du",
			"au": "au",
			"aucune-reserve": "Aucune réserve associée à cette intervention",
			"nouvelle-reserve": "Nouvelle réserve"
        },
        "en": {
            "date-previsionnelle": "Expected Date",
            "heure-debut": "Beginning hour",
            "heure-fin": "Ending hour",
            "date-effective-debut": "Start date",
            "date-effective-fin": "End date",
            "commentaire": "Comment",
            "documents": "Documents",
            "modal-validate-intervention-title": "Validate the intervention",
            "save": "Save",
            "cancel": "Cancel",
            "reserves": "Reservations",
            "description": "Description",
            "add-reserve": "Add reservation",
			"du": "from",
			"au": "to",
			"aucune-reserve": "No reservation associated with this intervention",
			"nouvelle-reserve": "New reservation"
        },
        "th": {
            "date-previsionnelle": "วันที่คาดการณ์",
            "heure-debut": "ชั่วโมงที่เริ่มต้น",
            "heure-fin": "ชั่วโมงที่สิ้นสุด",
            "date-effective-debut": "วันที่เริ่มต้น",
            "date-effective-fin": "วันที่สิ้นสุด",
            "commentaire": "ความคิดเห็น",
            "documents": "เอกสาร",
            "modal-validate-intervention-title": "ตรวจสอบการแทรกแซง",
            "save": "บันทึก",
            "cancel": "ยกเลิก"
        }
    }
},
        data: function(){
            return {
				intervention: this.value,
				fileToUpload: null,
				documents: [],
				metadatasIntervention: new Metadatas(),
				agfilters: {
					id: { attr: "id", colId: "id", value: this.value.id, action: "equals" }
				},
				reserves: [],
				reserve: {
					materiel: null,
					commentaireClient: null
				},
				equipementsFilters: {
					idCategorie_id: { attr:"e.idCategorie_id",colId: "idCategorie_id", value: this.value.contrat&&this.value.contrat.categorie?this.value.contrat.categorie.id:null, action:"contains" }
				},
				showEquipementsListModal: false,
				isLoading: false
            };
        },
		watch: {
			value:{
				handler: function(val){
					////console.log("WATCH V-MODEL", val);
					this.intervention = this.value;
					this.getDocumentsIntervention();
				},
				deep: true
			}
		},
		created: function(){
			this.fetchTags();
			this.intervention.dateEffectiveDebut = moment().format("YYYY-MM-DDTHH:00");
			this.intervention.dateEffectiveFin = moment().format("YYYY-MM-DDTHH:30");
			this.reserve.intervention_id = this.intervention.id;
		},
		mounted: function(){
			this.getDocumentsIntervention();
		},
		methods: {
			addReserve: function(){
				this.reserves.push(Object.assign({},{},this.reserve));
				this.reserve.materiel = null;
				this.reserve.commentaireClient = null;
			},
			removeReserve: function(reserve, index){
				this.reserves.splice(index, 1);
			},
			/**
			*
			*/
			getDocumentsIntervention: function(){
				this.documents = this.VgFilesMixins_normalizeFilesToListSection(this.intervention.documents, this.documentsInterventionTags);
			},
			/**
			*
			*/
			onUploadPhotoDone: function(e){
				this.InterventionsMixins_getIntervention(this.intervention.id);
			},
			/**
	        * Fetch tous les tags.
	        */
	        fetchTags: function(){
	            this.TagsMixins_get(new Metadatas()).then((datas)=>{
	                //console.log("TAGS", datas);
	            });
	        },
			onChangeDateEffectiveDebut: function(dt){
	            this.intervention.dateEffectiveFin = moment(dt).add(1, "hours").format("YYYY-MM-DDTHH:mm");
	        },
			/**
	        * Stock le document à uploader en attente de l'enregistrement de l'intervention.
	        * @param object file
	        */
	        onFileChange: function(file){
	            this.fileToUpload = file;
	        },
			/**
	        * Upload le document sur l'intervention.
	        */
	        uploadFileTo: function(){
	            if(this.fileToUpload){
	                let currentUid = this.VgFilesMixins_getUid();
	                this.VgFilesMixins_setUid(this.intervention.uid);
	                this.VgFilesMixins_uploadFile(this.fileToUpload.file, this.fileToUpload.idTag).then((datas)=>{
	                    this.VgFilesMixins_setUid(currentUid);
						// @TODO enlever le fetch et recup l'obj intervention avec documents etc associé au niveau du backend
						//this.metadatasIntervention.setFilters(this.agfilters);
						this.InterventionsMixins_getIntervention(this.intervention.id);
						//this.InterventionsMixins_get(this.metadatasIntervention);
	                });
	            }
	        },
			/**
			* Récupère la réserve non ajoutée à la liste des réserves à save
			* IE: si l'user n'appuie pas sur le bouton ajouter une réserve il peut y avoir une réserve saisie mais pas dans this.reserves
			*/
			getReserveNonAjoutee: function(){
				if(this.reserve.materiel && this.reserve.commentaireClient) this.addReserve();
			},
			afterSaveIntervention: function(){
				this.isLoading = false;
				if(this.isInterventionEquipementsCanBeRestricted) this.showEquipementsListModal = true;
				else this.$emit("save");
			},
			onSaveValidateIntervention: function(){
				this.isLoading = true;
				this.getReserveNonAjoutee();
				this.intervention.status = "intervention-realisee";
				this.InterventionsMixins_update(this.intervention).then((data)=>{
					//console.log("UPDATE INTERVENTION", data);
					this.uploadFileTo();
					if(this.reserves.length!=0){
						this.MaintenanceMixins_createMaintenances(this.getReserves, {_stored: false}).then((datas)=>{
							//console.log("MAINTENANCES CREATED", datas);
							this.afterSaveIntervention();
						});
					}else{
						this.afterSaveIntervention();
					}
				});
			}
		},
        computed:{
			...mapGetters({
	            documentsInterventionTags: "TagsStore/getDocumentsInterventionTags"
	        }),
			isAddReserveDisabled: function(){
				return !this.reserve.materiel || !this.reserve.commentaireClient || this.reserve.commentaireClient=="";
			},
			getReserves: function(){
				return this.reserves.map((reserve)=>{
					return {
						materiel_id: reserve.materiel.id,
						commentaireClient: reserve.commentaireClient,
						userId: this.$app.appID,
						idUser: this.$app.idUser,
						intervention_id: this.intervention.id,
						isReserve: true,
						dateOuvertureSAV: moment().format("YYYY-MM-DD HH:mm:ss"),
						origin: "intervention"
					}
				});
			},
			getDatePrevisionnelle: function(){
				return moment(this.value.datePrevisionnelleDebut).format("YYYY-MM-DD");
			},
			getHeurePrevisionnelleDebut: function(){
				return moment(this.value.datePrevisionnelleDebut).format("HH:mm");
			},
			getHeurePrevisionnelleFin: function(){
				return moment(this.value.datePrevisionnelleFin).format("HH:mm");
			},
            isInterventionEquipementsCanBeRestricted: function(){
                if(this.value && this.value.contrat_id) return true;
                else if(this.value && !this.value.contrat_id && !this.value.fichesav_id) return true;
                else return false;
            }
        }
    };
</script>
<style lang="scss" scoped>
.footer-intervention{
	width: 100px;
	margin-right: 10px;
	.upload-photo{
		height: 40px;
		margin: 0;
		align-items: center !important;
	}
}
.reserves-view{
	margin-bottom: 10px;
	.reserve-equipement-view{
		display:flex;
		justify-content:space-between;
		align-items:center;
		margin-bottom: 5px;
	}
}

.reserve-equipement-add{
	display:flex;
    flex-direction: column;
	justify-content:space-between;
	align-items:baseline;
}
</style>
